<template>
  <div class="pagecontainer">
    <adminheader></adminheader>
    <div class="adminbody bg-white">
      <sidenav active="Blogs"></sidenav>
      <div class="pagecontent">
        <div class="page-title">Edit Blog</div>
        <div class="page-list">
          <div class="selectimage">
            <span class="afrilabel">Header Image</span>
            <div class="afrigalleryselectedheader" v-if="blog && blog.headerimg">
              <img class="galleryheaderitem" :src="baseUrl + '/media/get/' + blog.headerimg.ref">
            </div>
            <div class="afrigallery">
              <img @click="selectImage(item)" class="afrigalleryitem" :src="baseUrl + '/media/get/' + item.ref" v-for="item in media" :key="item.ref" alt="">
            </div>
          </div>
          <div class="form-input-afri">
            <span class="afrilabel">Title</span>
            <input type="text" v-model="blog.title">
          </div>
          <div class="form-input-afri">
            <span class="afrilabel">Content</span>
            <textarea v-model="blog.content" class="blog-content"></textarea>
          </div>
          <div class="toolbox">
            <span class="newbutton" @click="saveBlog()">Save Changes</span>
            <span class="publishbutton" v-if="!blog.active" @click="publishBlog()">Publish</span>
            <span class="unpublishbutton" v-if="blog.active" @click="unpublishBlog()">Unpublish</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import adminheader from '@/components/admin/header.vue'
import axios from 'axios'
import Sidenav from '@/components/sidenav.vue'
import router from '@/router'

export default {
  name: 'BlogEdit',
  components: {
    adminheader,
    Sidenav
  },
  data: function () {
    return {
      blog: {
        title: '',
        content: '',
        headerimg: ''
      },
      media: [],
      baseUrl: this.$store.state.baseUrl,
      editor: null,
      value: {
        type: String,
        default: ''
      }
    }
  },
  methods: {
    publishBlog: function () {
      const myFormData = new FormData()
      myFormData.set('blog', this.blog.ref)

      axios(
        {
          method: 'post',
          data: myFormData,
          url: this.$store.state.baseUrl + '/api/admin/blog/publish',
          headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + this.$store.state.token }
        })
        .then((response) => {
          console.log(response)
          this.blog = response.data.blog
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    unpublishBlog: function () {
      const myFormData = new FormData()
      myFormData.set('blog', this.blog.ref)

      axios(
        {
          method: 'post',
          data: myFormData,
          url: this.$store.state.baseUrl + '/api/admin/blog/unpublish',
          headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + this.$store.state.token }
        })
        .then((response) => {
          console.log(response)
          this.blog = response.data.blog
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    saveBlog: function () {
      const myFormData = new FormData()
      myFormData.set('blog', this.blog.ref)
      myFormData.set('title', this.blog.title)
      myFormData.set('content', this.blog.content)
      myFormData.set('media', this.blog.headerimg.ref)
      axios(
        {
          method: 'post',
          data: myFormData,
          url: this.$store.state.baseUrl + '/api/admin/blog/edit',
          headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + this.$store.state.token }
        })
        .then((response) => {
          console.log(response)
          router.push('/admin/blog')
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    isSelectedItem (item) {
      return this.blog.headerimg === item
    },
    getMedia: function () {
      axios(
        {
          method: 'post',
          url: this.$store.state.baseUrl + '/api/media',
          headers: { Authorization: 'Bearer ' + this.$store.state.token }
        })
        .then((response) => {
          console.log(response)
          this.media = response.data.media
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    selectImage (imageref) {
      this.blog.headerimg = imageref
      console.log('Selected image ' + imageref)
    }
  },
  created () {
    this.getMedia()
    if (this.$store.state.selectedblog) {
      this.blog = this.$store.state.selectedblog
      console.log(JSON.stringify(this.blog))
    }
  }
}
</script>
<style scoped>
.pagecontainer {
    display: flex;
    flex-direction: column;
  }

  .blog-content {
    height: 50vh;
  }

  .btn-open-package {
    background-color: #4FC3F7;
    color: #fff;
    font-weight: 700;
    padding: 10px 10px;
    border-radius: 5px;
  }
  .adminbody {
    display: grid;
    grid-template-columns: 250px 1fr;
  }

  .pagecontent {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .page-title {
    padding: 20px;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .page-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .form-input-afri {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .afrilabel {
    font-size: 12px;
    font-weight: 600px;
    color: #000;
  }

   .toolbox {
    width: 100%;
    background: #9E9E9E;
    height: 10vh;
    padding: 2vh;
    display: grid;
    grid-template-columns: 200px 1fr;
  }

  .newbutton {
    background: #00BFA5;
    color: #fff;
    font-weight: 600;
    width: 150px;
    padding: 10px;
    border-radius: 5px;
  }

  .publishbutton {
    background: #FFD600;
    color: #fff;
    font-weight: 600;
    width: 150px;
    padding: 10px;
    border-radius: 5px;
  }

  .unpublishbutton {
    background: #E0E0E0;
    color: #fff;
    font-weight: 600;
    width: 150px;
    padding: 10px;
    border-radius: 5px;
  }

  .afrigallery {
    height: 20vh;
    width: 100%;
    display: flex;
    gap: 10px;
    margin-top: 20px;
    flex-wrap: wrap;
    overflow: scroll;
  }

  .afrigalleryitem {
    height: 17vh;
    width: 200px;
    object-fit: cover;
  }

  .afrigalleryitemselected {
    height: 17vh;
    width: 200px;
    object-fit: cover;
    border: 3px solid #00E5FF;
  }

  .afrigalleryselectedheader {
    background: #9E9E9E;
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .galleryheaderitem {
    height: 100%;
    width: 800px;
    object-fit:cover;
  }
</style>
