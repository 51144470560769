<template>
  <div class="flex flex-wrap w-full gap-2 pb-8">
    <div class="rounded p-2 bg-white text-center shadow-xl w-72 items-center mb-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-grey-500 duration-300" v-for="item in bookings" :key="item.ref">
      <div class="">
        <img class="h-40 w-full object-cover" v-lazy="{src:mediaUrl + item.package.headerimage.ref,loading:'/images/loader.gif',error:'/images/loader.gif'}" alt="">
        <div class="flex flex-col w-full">
          <div class="text-lg truncate">{{item.package.name}}</div>
          <p class="text-sm max-h-5 truncate">{{item.package.description}}</p>
        </div>
        <p class="shadow-md px-5 py-2.5 rounded text-white bg-aforange-normal cursor-pointer" @click.prevent="showBookingDetails(item)">Show Details</p>
      </div>
      <div class="card-footer text-muted">
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import router from '@/router'
export default {
  name: 'PackageBookings',
  props: [],
  data: function () {
    return {
      baseUrl: this.$store.state.baseUrl,
      token: this.$store.state.token,
      auth: this.$store.state.loggedin,
      mediaUrl:this.$store.state.baseUrl+'/media/get/',
      bookings: []
    }
  },
  methods: {
    getBookings: function () {
      axios(
        {
          method: 'POST',
          url: this.baseUrl + '/api/booking/get/user',
          headers: { Authorization: 'Bearer ' + this.token, 'Access-Control-Allow-Origin':'*' }
        })
        .then((response) => {
          console.log('bookings response' + JSON.stringify(response))
          this.bookings = response.data.bookings
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getPackage: function (tpackage) {
      axios(
        {
          method: 'post',
          url: this.baseUrl + '/api/get/package/' + tpackage,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token','') }
        })
        .then((response) => {
          console.log('bookings response' + JSON.stringify(response))
          this.bookings = response.data.bookings
        })
        .catch(function (error) {
          console.log(error)
        })
    },

    showBookingDetails: function(_item)
    {
      console.log("PACKAGE: "+_item.package.ref);
      this.$store.commit('setSelectedBooking', _item)
      this.$store.dispatch('setSelectedTravelPackage',_item.package)
      router.push('/booking/user/details/' + _item.ref)
    },
    isAuth: function () {
      if (this.auth) {
        this.getBookings()
      } else {
        router.push('/login')
      }
    }
  },
  computed:{

    hasbookings(){
      return (typeof this.bookings != undefined && this.bookings != null && this.bookings.length > 0)
    }
  },
  created () {
    this.isAuth()
  }
}
</script>

<style scoped>

.header_img {
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card {
  padding: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-left: 10px;
}

.card-body {
  padding: 0px;
}

.details-btn {
  margin-top : 5px;
  margin-bottom : 10px;
}

.list-container {
  padding: 10px;
  width: 100%;
}

.member-bookings-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 50px;
}

</style>
