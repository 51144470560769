<template>

<a href="#" v-if="blog" class="flex flex-col items-center border p-2  rounded-lg shadow w-56 sm:w-80  md:w-96 max-w-xs md:flex-row md:max-w-sm border-gray-700 bg-gray-800 hover:bg-gray-700 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-grey-500 duration-300" v-on:click.prevent="openblog(blog)">
  <img v-if="blog.headerimg" class="object-cover md:w-1/3 w-full rounded-tr-2xl rounded-bl-2xl h-full md:max-w-40 p-1 bg-slate-200"
    v-lazy="{ src:mediaUrl + blog.headerimg.ref,loading: '/images/loader.gif', error: '/images/loader.gif' }" alt=""/>
 
    <div  v-if="blog" class="flex flex-col md:w-2/3 w-full justify-between p-2 leading-normal">
        <div class="mb-2 text-xl font-bold tracking-tight text-gray-300 whitespace-pre-wrap overflow-x-auto overflow-y-hidden">{{blog.title}}</div>
        <small class="text-white">{{moment(blog.created_at).format('MMM Do YY')}}</small>
        <p class="mb-3 font-normal text-gray-400">Read More...</p>
    </div>
</a>
  <!-- <div  class="grid grid-cols-3 rounded bg-black p-4" v-on:click="openblog(blog)">

    <div class="col-span-1">
      <img v-if="blog.headerimg" class="w-full h-full" :src="baseUrl + '/media/get/' + blog.headerimg.ref" alt="">
    </div>
     blog-content 
    <div class="col-span-2 items-center justify-center sm:max-w-xs">
      <p class="text-lg text-white break-words max-w-xs">{{blog.title}}</p>
      <small class="text-white">{{moment(blog.created_at).format('MMM Do YY')}}</small>
      <div class="">
        <p class="text-sm font-bold cursor-pointer text-blue-100 truncate">Read More...</p>
      </div>
    </div>
  </div> -->
</template>
<script>
import router from '@/router'
import moment from 'moment'
export default {
  name: 'BlogPost',
  components: {
  },
  methods: {
    openblog: function (selectedblog) {
      console.log("Selected blog: "+JSON.stringify(selectedblog))
      this.$store.commit('setSelectedBlog', selectedblog)
      router.push('/blog/view')
    }
  },
  data: function () {
    return {
      baseUrl: this.$store.state.baseUrl,
      mediaUrl:this.$store.state.baseUrl+'/media/get/',
      moment: moment
    }
  },
  props: ['blog']
}
</script>
<style scoped>
#blogpost {
    display: grid;
    grid-template-columns:auto 1fr;
    border-radius: 10px;
    width:auto;
    background-color: #212121;
    align-content: center;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }

#blogpost:hover {
  cursor: pointer;
}
.title {
  font-weight: 800;
  font-size: 1rem;
  color: #fff;
}
.created_at {
  font-weight: 400;
  font-size: 0.6rem;
  color: #fff;
}

.blog-readmore {
  color: #dce8ee;
  font-size: 0.8rem;
  cursor: pointer;
  font-weight: 700;
}
.blog-header {
  height: 70px;
  width: 100px;
  object-fit: cover;
  border-radius: 10px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 0px;
  z-index: 10;
}

.blog-content {
  padding: 10px;
  width: 100%;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.blog-footer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 80px;
}

</style>
