<template>
  <div class="flex flex-col p-5 rounded shadow bg-white gap-2 my-3 mx-2 h-32 w-64">
    <img class="packageimage" v-if="imageitem" :src="baseUrl + '/media/get/' + imageitem.ref" alt="">
    <div class="packagename">{{ imageitem.name }}</div>
  </div>
</template>

<script>
export default {
  name: 'MediaList',
  props: ['imageitem'],
  data: function () {
    return {
      baseUrl: this.$store.state.baseUrl
    }
  },
  methods: {
  }
}
</script>

<style>
.package-row {
  display: flex;
  flex-direction: row;
  background: #ECEFF1;
  width: 100%;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  gap: 10px;
  box-shadow: 1px 2px 1px -2px rgba(0,0,0,0.48);
  -webkit-box-shadow: 1px 2px 1px -2px rgba(0,0,0,0.48);
  -moz-box-shadow: 1px 1px 2px -2px rgba(0,0,0,0.48);
  margin-bottom: 10px;
}

.packageimage {
  height: 150px;
  width: 100%;
  object-fit: cover;
}

.packagename {
  font-weight: 600;
  color: #2196F3;
}

.packagedesc {
  color: #000;
  font-size: 0.9rem;
}

</style>
