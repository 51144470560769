<template>
   
    <div class="container mx-1 md:mx-auto h-screen overflow-y-auto py-10">
      <SuccessComponent :success="message" v-if="showModal" @closeSuccess="closeMessage"></SuccessComponent>
 
        <div class="flex flex-col items-center w-full justify-center">
        <div class="font-bold text-2xl py-4">
        Contact Us
        </div>
      <div class="flex flex-col justify-center items-center gap-2 h-full  whitespace-pre-line text-center pb-8 pr-4 md:pb-10 w-full">
        <div class="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8">
          <form>
    <div class="grid gap-6 mb-4 md:grid-cols-2">
        <div>
            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 ">First name</label>
            <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="John" v-model="first_name" required>
        </div>
        <div>
            <label for="last_name" class="block mb-2 text-sm font-medium text-gray-900 ">Last name</label>
            <input type="text" id="last_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="Doe"  v-model="last_name">
        </div>
        <div>
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 ">Email address</label>
        <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="john.doe@company.com" v-model="email" required>
        </div>  
        <div>
            <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 ">Phone number</label>
            <input type="tel" id="phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"  v-model="phone" required>
        </div>
        
    </div>
   
    <div class="mb-6">
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 ">Message</label>
            <textarea type="text" id="message" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" placeholder="Message" v-model="text_message"></textarea>
    </div>
    
    <button type="submit" class="text-black bg-aforange-normal  hover:opacity-75 focus:ring-4 focus:outline-none focus:ring-[#e87c61]-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center " @click.prevent="saveContact()">Submit</button>
</form>
        </div>

  
      </div>
      
      </div>
      </div>
     

  </template>
  <script>
import SuccessComponent from '../components/SuccessComponent.vue'
import axios from 'axios'
  export default {
    name: 'ContactUs',
    components: {
    SuccessComponent
},
    data: function () {
      return {
        baseUrl: this.$store.state.baseUrl,
        loggedIn: this.$store.state.loggedIn,
        email:"",
        text_message:"",
        first_name:"",
        last_name:"",
        phone:"",
        show_preloader:true,
        showModal:false,
      message:{
                message:"",
                code:""
            },
      }
    },
    methods: {

      closeMessage:function(){
       this.message.message = ''
       this.showModal = false;
    },
    clearForm:function(){
       this.text_message = ""
       this.email = ""
       this.first_name = ""
       this.last_name = ""
       this.phone = ""
    },
      setLoaded: function () {
      this.loaded = true
    },
      saveContact: function () {
      const myFormData = new FormData()
 
      myFormData.set('content', this.text_message)
      myFormData.set('name',this.first_name +" "+ this.last_name)
      myFormData.set('email',this.email)
      myFormData.set('mobile',this.phone)
    

      this.show_preloader = true
      try {
        axios(
        {
          method: 'post',
          data: myFormData,
          url: this.baseUrl + '/api/contactus/save',
          headers: { ContentType: 'application/json' }
        })
        .then((response) => {
          this.show_preloader = false
          console.log('contact saved response : ' + JSON.stringify(response.data))
          this.showModal = true;
          this.message.message = "Thank you for contacting us we will get back to you as soon as possible"
          //this.$emit('refreshPayments',response.data.payments);
          this.clearForm()
        })
        .catch(function (error) {
          this.show_preloader = false
          this.message.message = error
          console.log(error)
        })
    
      } catch (error) {
        this.show_preloader = false
        this.message.message = error
        console.log(error)
      }
    }

    },
    mounted () {
    },
    created () {
    }
  }
  </script>
  <style scoped>
    .section h2 {
      color: black;
      font-size: 2em;
    
    }
    svg{
      display: inline-flex;
    }
  </style>
  