<template>
  <div class="flex flex-col" v-if="active">
    <div class="flex flex-col h-screen w-screen justify-center items-center">
      <div class="bg-white p-4 rounded h-3/4 w-3/4 mb-20">
        <div class="overflow-y-auto h-full">
        <div class="md:h-full w-full flex flex-col md:flex-row overflow-hidden overscroll-y-auto">
          <div class="flex flex-col md:w-1/2 justify-center items-center">
            <div class="text-2xl font-extrabold px-5 text-center">
              CULTURAL TRAVEL
            </div>
            <div class="mt-4 px-2.5 md:px-5 whitespace-pre-wrap">
              “Experience culture the Afrigetaway way”
              
              
              Enjoy unforgettable and truly inspiring experiences that will touch you in an emotional way and connect you with that specific place, people and culture (intangible/immaterial culture).
            </div>
            <img class="hidden md:block object-contain mb-5" src="/images/kids.png" alt="">
          </div>
          <div class="flex flex-col md:w-1/2">
            <img  v-lazy="{src:'/images/package_category_bg_cultural.png', loading:'/images/loader.gif',error:'/images/loader.gif'}"  alt="" class="w-full h-full object-cover rounded-t bg-slate-200"/>
            <button class="p-2 bg-teal-500 flex justify-center items-center text-white font-bold rounded-b cursor-pointer focus:animate-pulse transition duration-150 ease-in-out" @click.prevent="
            openPackages('cultural')">View Travel Packages</button>
          </div>
        </div>
        </div>
      </div>
     
    </div>
  </div>
</template>
<script>
import router from '@/router'
import bgImg from '@/assets/images/afribg.png'
export default {
  props: ['active'],
  data: function () {
    return {
      jumtitles: ['EXPLORE', 'TRAVEL', 'CONNECT'],
      currtitle: 0,
      packages: [],
      bgImg,
      search: 0,
      categories: ['Cultural Travel', 'Luxury Safari', 'Creative Travel']
    }
  },
  methods: {
    openPackages: function (_package) {
      this.$store.commit('setSelectedPackage', _package)
      router.push('/list/packages')
    }
  },
  mounted: function () {
    setInterval(() => {
      if (this.currtitle < this.jumtitles.length - 1) {
        this.currtitle++
      } else {
        this.currtitle = 0
      }
    }, 1000)
  }

}
</script>
<style>

</style>
