<template>
  
    <div v-if="travelpackage.headerimage" class="package-container sm:w-full md:w-96" v-on:click="openPackage(travelpackage.ref)">
    <img :src="baseUrl + '/media/get/' + travelpackage.headerimage.ref" alt="" width="200" height="300">
    <div class="name-price">
      <div class="pkg-title">
        {{travelpackage.name}}
      </div>
      <div class="pkg-price">
        From USD {{travelpackage.price}}
      </div>
    </div>
    <div class="pkg-desc">
      {{travelpackage.description}}
    </div>
    <div class="packageviewbg">
    </div>
  </div>
 
</template>
<script>
import router from '@/router'
import axios from 'axios'
export default {
  data: function () {
    return {
      baseUrl: this.$store.state.baseUrl,
      token: this.$store.state.token,
      packages: []
    }
  },
  props: {
    travelpackage: {
      headerimage: null
    },
    itenary_days: []
  },
  methods: {
    openPackage: function (item) {
      router.push({ path: `/booking/${item}` })
    },
    getItenaryDays: function () {
      const myFormData = new FormData()
      myFormData.set('package', this.travelpackage.ref)

      axios(
        {
          method: 'post',
          data: myFormData,
          url: this.$store.state.baseUrl + '/api/admin/package/get/itenarydays',
          headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + this.$store.state.token, 'Access-Control-Allow-Origin': '*' }
        })
        .then((response) => {
          console.log(response)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getpackages: function () {
      axios(
        {
          method: 'post',
          url: this.$store.state.baseUrl + '/api/get/packages'
        })
        .then((response) => {
          console.log(response.data.data)
          if (typeof this.users !== 'undefined' || this.users !== null) {
            this.packages = response.data.data
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  mounted () {
    this.getpackages()
    //this.getItenaryDays()
  }
}
</script>
<style>
  .package-container {
    padding: 5px;
    border-radius: 10px;
    background: #212121;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    -moz-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  .package-container:hover {
    transform: scale(1.01);
    transition-duration: 1s;
  }
  .package-container img {
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .pkg-title {
    font-size: 18px;
    font-weight:700;
    color: #FFD600;
  }

  .pkg-price {
    font-size: 0.8rem;
    font-weight:600;
    color: #4DB6AC;
    border-radius: 5px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .name-price {
    display: grid;
    grid-template-columns: 1fr 100px;
  }

  .pkg-desc {
    height: 3em;
    min-height: 70px;
    font-size: 0.8rem;
    color: #fff;
    text-overflow:ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
  }
</style>
