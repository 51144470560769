import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/Aboutus.vue'
import Contact from '@/views/Contactus.vue'
import Book from '@/views/Book.vue'
import Bookingcomplete from '@/views/Bookingcomplete.vue'
import Login from '../views/Admin/Login.vue'
import Dashboard from '@/views/Admin/Dashboard.vue'
import Users from '@/views/Admin/Users/index.vue'
import Packages from '@/views/Admin/Packages/index.vue'
import Bookings from '../views/Admin/Bookings/index.vue'
import Media from '@/views/Admin/Media/index.vue'
import Destinations from '@/views/Admin/Destinations/index.vue'
import MemberDashboard from '@/views/Public/Dashboard.vue'
import NewPackage from '@/views/Admin/newpackage.vue'
import ViewPackage from '../views/Admin/viewpackage.vue'
import PackagesLists from '@/views/PackagesLists.vue'
import AdminBlog from '@/views/Admin/Blogs/index.vue'
import AdminBlogNew from '@/views/Admin/Blogs/new.vue'
import AdminBlogEdit from '@/views/Admin/Blogs/edit.vue'
import BookingsMemDetails from '@/components/public/bookingsmemdetails'
import BlogView from '@/views/Blogview.vue'


const isAuthenticated = function() {
 
 
  if (localStorage.getItem('access_token')) {
    console.log("Sesh routes.."+localStorage.getItem('access_token'));
    return true;
  }
  else {
    console.log("Session routes: " + localStorage.getItem('access_token'));
    return false;
  }
}       

function authenticated(to, from,next){
  if (!isAuthenticated() && to.name !== 'Login') 
  {
  // redirect the user to the login page
  next({ name: 'Login' })
}
else
{
  next();
}
}

const routes = [
  {
    path: '/',
    name: 'IndexPage',
    component: Home
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'Aboutus',
    component: About
  },
  {
    path: '/contact',
    name: 'Contactus',
    component: Contact
  },
  {
    path: '/blog/view',
    name: 'ViewBlog',
    component: BlogView
  },
  {
    path: '/packages',
    name: 'Package',
    component: Home
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Home
  },
  {
    path: '/events',
    name: 'Events',
    component: Home
  },
  {
    path: '/booking/:packageref',
    name: 'Book',
    component: Book
  },
  {
    path: '/booking/complete/:packageref',
    name: 'Bookingcomplete',
    component: Bookingcomplete
   
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/member/dashboard',
    name: 'MemberDashboard',
    component: MemberDashboard,
    beforeEnter:authenticated
  },
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter:authenticated
  },
  {
    path: '/admin/users',
    name: 'Users',
    component: Users,
    beforeEnter:authenticated
  },
  {
    path: '/admin/packages',
    name: 'Packages',
    component: Packages,
    beforeEnter:authenticated
  },
  {
    path: '/admin/bookings',
    name: 'Bookings',
    component: Bookings,
    beforeEnter:authenticated
  },
  {
    path: '/admin/media',
    name: 'Media',
    component: Media,
    beforeEnter:authenticated
  },
  {
    path: '/admin/blog',
    name: 'AdminBlog',
    component: AdminBlog,
    beforeEnter:authenticated
  },
  {
    path: '/admin/blog/new',
    name: 'AdminBlogNew',
    component: AdminBlogNew,
    beforeEnter:authenticated
  },
  {
    path: '/admin/edit/blog',
    name: 'AdminBlogEdit',
    component: AdminBlogEdit,
    beforeEnter:authenticated
  },
  {
    path: '/admin/destinations',
    name: 'Destinations',
    component: Destinations,
    beforeEnter:authenticated
  },
  {
    path: '/admin/new/package',
    name: 'newPackage',
    component: NewPackage,
    beforeEnter:authenticated
  },
  {
    path: '/admin/view/package/:packageref',
    name: 'viewPackage',
    component: ViewPackage,
    beforeEnter:authenticated
  },
  {
    path: '/list/packages',
    name: 'packagesLists',
    component: PackagesLists,
  },
  {
    path: '/booking/user/details/:packageref',
    name: 'bookingmemdetails',
    component: BookingsMemDetails,
    beforeEnter:authenticated
  },
  {
    path: '/admin/view/blog',
    name: 'AdminViewBlog',
    component: () => import('@/views/Admin/Blogedit.vue'),
    beforeEnter:authenticated
  },
  {
    path: '/admin/package/edit',
    name: 'EditPackage',
    component: () => import('@/views/Admin/Packages/edit.vue'),
    beforeEnter:authenticated
  },
  {
    path: '/admin/package/new',
    name: 'NewPackage',
    component: () => import('@/views/Admin/Packages/new.vue'),
    beforeEnter:authenticated
  },
  {
    path: '/home/destinations',
    name: 'DestinationsViews',
    component: () => import('@/components/public/destinations.vue')
  },
  {
    path: '/home/destinations/:destinationref',
    name: 'DestinationsView',
    component: () => import('@/components/public/destinations.vue')
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export default router
