<template>
  <div class="text-sm font-light text-gray-500  dark:text-gray-400">
    <ul class='flex flex-wrap -mb-px' v-bind="$attrs">
      <li v-for='(title, index) in tabTitles'
        :key='title'
        @click='selectedTitle = title'
        class="bg-slate-300 text-base font-bold py-4 px-1  mr-2 rounded-t-lg border-b-2 border-transparent"
        :class='{"text-yellow-600 border-yellow-500 ": (title == selectedTitle)}'>
        {{ title }}
      </li>
    </ul>

    <div>
    <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, useSlots ,provide} from 'vue';

  
const _slots = useSlots();



const tabTitles = ref(_slots.default?.().map((tab) => tab?.props?.title));

const selectedTitle = ref(tabTitles?.value?.[0]);

provide('selectedTitle', selectedTitle);

onMounted(() => {

});
 
  
  

</script>

<style lang="css">

  ul.tabs__header {
    display: block;
    list-style: none;
    margin: 0 0 0 20px;
    padding: 0;
  }

  ul.tabs__header > li {
    padding: 15px 30px;
    border-radius: 10px;
    margin: 0;
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
  }

  ul > li.tab__selected {
    font-weight: bold;
    border-radius: 10px 10px 0 0;
    border-bottom: 8px solid transparent;
  }

  .tab {
    display: inline-block;
    color: black;
    padding: 20px;
    min-width: 800px;
    border-radius: 10px;
    min-height: 400px;
  }

  .tabs__light .tab{
    background-color: #fff;
  }

  .tabs__light li {
    background-color: #ddd;
    color: #aaa;
  }

  .tabs__light li.tab__selected {
    background-color: #fff;
    color: #83FFB3;
  }

  .tabs__dark .tab{
    background-color: #555;
    color: #eee;
  }

  .tabs__dark li {
    background-color: #ddd;
    color: #aaa;
  }

  .tabs__dark li.tab__selected {
    background-color: #555;
    color: white;

  }

</style>
