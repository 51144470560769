<template>
    <div class="flex flex-row justify-start mx-4 mt-8 mb-6">
      <RouterLink :to="to" class="flex flex-row text-yellow-500 text-base md:text-lg items-center gap-2">
            <svg class="h-6 w-4" xmlns="http://www.w3.org/2000/svg" width="43" height="49" viewBox="0 0 43 49"><path d="M24.5,0,49,43H0Z" transform="translate(0 49) rotate(-90)" fill="#f6b45e"/></svg>        
            Back
        </RouterLink>
    </div>
</template>
<script>
export default {
    name: 'BackButton',
    props: ['to'],
    methods:{

    }
}
</script>