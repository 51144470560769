<template>
  <div class="min-h-screen min-w-full flex ">
      <sidenav active="Packages"></sidenav>
      <div class="flex flex-col w-full p-4 min-h-screen bg-slate-100 overflow-scroll">
        <div class="page-title">Packages</div>
        <div class="bg-white shadow-sm rounded p-4 flex gap-4">
          <div class="bg-teal-500 text-white font-bold flex flex-col p-2 rounded shadow justify-center items-center">
            <span>Total Packages</span>
            <span>{{ packages.length }}</span>
          </div>
          <div class="bg-blue-500 text-white font-bold flex flex-col p-2 rounded shadow justify-center items-center">
            <span>Published Packages</span>
            <span>{{ packages.length }}</span>
          </div>
          <div class="bg-yellow-500 text-white font-bold flex flex-col p-2 rounded shadow justify-center items-center">
            <span>Upublished Packages</span>
            <span>{{ packages.length }}</span>
          </div>
          <div @click.prevent="newPackage()" class="bg-emerald-400 p-2 text-white rounded shadow-sm hover:scale-110 duration-500 font-bold cursor-pointer flex flex-col justify-center items-center">
            <span>New Package</span>
            <i class="lni lni-plus font-bold"></i>
          </div>
        </div>
        <div class="w-full flex flex-col p-4 overflow-scroll mb-8">
          <!-- Table -->
        <div class="flex flex-row w-full bg-white  border border-gray-200 shadow-sm">
            <div class="p-3">
                <div class="overflow-x-auto">
                    <table class="table-auto w-full">
                        <thead class="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                            <tr>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold text-left">Name</div>
                                </th>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold text-left">Description</div>
                                </th>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold text-left">Ref</div>
                                </th>
                                <th class="p-2 whitespace-nowrap">
                                    <div class="font-semibold text-center">Active</div>
                                </th>
                            </tr>
                        </thead>

                        <div class="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 " v-if="!page_ready">
                            <div class="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-4 h-16 w-16"></div>
                        </div>

                        <tbody class="text-sm divide-y divide-gray-100" v-if="page_ready">
                            <tr v-for="packageitem in packages" :key="packageitem.ref"  @click="openPackage(packageitem)" class="cursor-pointer hover:bg-sky-700 hover:text-white">
                                <td class="p-2 whitespace-nowrap">
                                    <div class="flex items-center">
                                      <div class="font-medium">{{packageitem.name}}</div>
                                    </div>
                                </td>
                                <td class="p-2 whitespace-nowrap">
                                    <div class="text-left">{{packageitem.description.substr(0,50)}}...</div>
                                </td>
                                <td class="p-2 whitespace-nowrap">
                                    <div class="text-left font-medium text-green-500">{{packageitem.ref}}</div>
                                </td>
                                <td class="p-2 whitespace-nowrap">
                                  <div v-if="packageitem.active === '1'" class="text-xs text-center bg-blue-400 rounded">Active</div>
                                  <div v-else class="text-xs text-center bg-red-400 text-white rounded">Not Active</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>
</template>
<script>
import axios from 'axios'
import Sidenav from '@/components/sidenav.vue'
import router from '@/router'

export default {
  name: 'PackageDashboard',
  components: {
    Sidenav
  },
  data: function () {
    return {
      packages: [],
      baseUrl: this.$store.state.baseUrl,
      page_ready: false
    }
  },
  methods: {
    getpackages: function () {
      axios(
        {
          method: 'post',
          url: this.$store.state.baseUrl + '/api/admin/packages'
        })
        .then((response) => {
          console.log(response.data.data)
          this.packages = response.data.data
          this.page_ready = true
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    newPackage: function () {
      router.push('/admin/package/new')
    },
    openPackage: function (packageitem) {
      this.$store.commit('setSelectedPackage', packageitem)
      router.push('/admin/package/edit')
    }
  },
  created () {
    this.getpackages()
  }
}
</script>
<style scoped>
.pagecontainer {
    display: flex;
    flex-direction: column;
  }

  .btn-open-package {
    background-color: #4FC3F7;
    color: #fff;
    font-weight: 700;
    padding: 10px 10px;
    border-radius: 5px;
  }

  .pagecontent {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .page-title {
    padding: 20px;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .page-list {
    width: 800px;
  }

  .tool-row {
    width: 100%;
    border-top: solid 0.5px rgb(117, 117, 117);
    border-bottom: solid 0.5px rgb(117, 117, 117);
    height: 7vh;
    padding: 5px;
  }

  .new-button {
    padding: 8px 16px;
    background: #4FC3F7;
    color: #fff;
    font-weight: 700;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }
</style>
