<template>
<div class="container md:mx-auto h-screen">
  <div class="h-screen overflow-y-auto pb-10">
    <backButtonComponent to="/member/dashboard"></backButtonComponent>
  <div class="">
    <SuccessComponent :success="message" v-if="showModal" @closeSuccess="closeMessage"></SuccessComponent>

  <TabsComponent>
    <TabComponent title="Booking Details">
     
        <div class="flex flex-col h-[28rem] md:h-[40rem]  w-full pb-10" v-if="hasBooking">
            <div class="flex flex-col-reverse md:flex-row m-2 ">
            <div class="flex flex-col p-4 gap-2 md:1/2">
              <span class="text-xl font-bold">{{ selectedbooking.package.name }}</span>
              <span class="text-sm">{{ selectedbooking.package.description }}</span>
              
              
          
                <IternaryDays type="list"/>
                
              
            </div>
        <div class="flex flex-col mx-2 md:1/2">
          <img class="bg-slate-200 rounded w-full drop-shadow-xl max-h-96 object-cover" v-lazy="{ src: mediaUrl + selectedbooking.package.headerimage.ref, loading: '/images/loader.svg', error: '/images/loader.svg' }">
        </div>
      </div>
      <div class="flex flex-col md:flex-row p-4 m-2 rounded border-t-2 ">
        <div class="flex flex-col my-2 md:w-1/2">
          <span class="text-lg font-bold my-2">
            Banking Details
          </span>
          <p>ABSA Bank Botswana</p>
          <p>Mall Branch</p>
          <p>Branch Code:001</p>
          <p>Account # : 1507352</p>
          <p>SWIFT:BARCBWGX</p>   
        </div>
        <div class="flex flex-col md:w-1/2">
          <span class="text-lg font-semibold" v-if="selectedbooking.package.availability !== 'local'">Total Amount: USD {{ selectedbooking.total_amount }}</span>
        <span v-else class="text-lg font-semibold">Total Amount: BWP {{ selectedbooking.total_amount}}</span>
        <div id="paymentcontainer" >
          <PaymentForm :selectedbooking="selectedbooking" @refreshPayments="refreshPayments()"></PaymentForm>
        </div>
        </div>
    
    
       
      </div>
          </div>
    </TabComponent>
    <TabComponent title="Booking Payments">
      <div class="flex flex-col max-h-[28rem] md:max-h-[40rem] overflow-y-auto mb-3">
        <div class="flex flex-col m-2" v-if="hasBooking">

          <div class="flex flex-col w-full border-b border-gray-200" v-for="payment in selectedbooking.payments">
          <div class="flex flex-col md:flex-row md:justify-center w-full p-4">
              <div class="flex flex-col w-full md:justify-between">
                <div class="flex flex-row mb-2 ">
                    <div class="pr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-afgreen-normal" v-if="payment.approved == '1'"><path stroke-linecap="round" stroke-linejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" /></svg>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-afpinkred-normal" v-if="payment.approved == '0'">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 15h2.25m8.024-9.75c.011.05.028.1.052.148.591 1.2.924 2.55.924 3.977a8.96 8.96 0 01-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398C20.613 14.547 19.833 15 19 15h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 00.303-.54m.023-8.25H16.48a4.5 4.5 0 01-1.423-.23l-3.114-1.04a4.5 4.5 0 00-1.423-.23H6.504c-.618 0-1.217.247-1.605.729A11.95 11.95 0 002.25 12c0 .434.023.863.068 1.285C2.427 14.306 3.346 15 4.372 15h3.126c.618 0 .991.724.725 1.282A7.471 7.471 0 007.5 19.5a2.25 2.25 0 002.25 2.25.75.75 0 00.75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 002.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384" /></svg>
                    </div>
                    <div class="flex flex-col">
                      <div class="truncate">{{ payment.payment_ref }}</div>
                    <div class="text-xs">{{ moment(payment.created_at).format('DD/MM/yyyy HH:mm:ss') }}
                  </div>
                    </div>
                  </div>
                
                </div>
              <div class="flex flex-row text-xs p-1 justify-center md:justify-end items-center" >
               
               <a :href="this.$store.state.baseUrl +'/payment/download/'+payment.ref" class="bg-aforange-normal rounded p-2 text-white cursor-pointer" download>
                Download
               </a> 
              
              </div>
            </div>
          </div>
        </div>

        <div v-if="!hasBookingPayments">
          <p>No payments found</p>
        </div>
      </div>
    </TabComponent>
  </TabsComponent>

</div>
  </div>
 
</div>
</template>
<script>
import axios from 'axios'
import router from '@/router'
import backButtonComponent from '../backButtonComponent.vue'
import PaymentForm from './paymentForm.vue'
import moment from 'moment'
import TabsComponent from '../TabsComponent.vue'
import TabComponent from '../TabComponent.vue'
import TheModalComponent from '../public/popUpModal.vue'
import SuccessComponent from './../SuccessComponent.vue'
import IternaryDays from './Packages/IternaryDays.vue'

export default {
  name: 'PackageMemDetails',
  props: [],
  components: {
    backButtonComponent,
    PaymentForm,
    TabsComponent,
    TabComponent,
    TheModalComponent,
    SuccessComponent,
    IternaryDays
},
  data: function () {
    return {
      baseUrl: this.$store.state.baseUrl,
      token: this.$store.state.token,
      auth: this.$store.state.loggedin,
      mediaUrl:this.$store.state.baseUrl+'/media/get/',
      afribooking: this.$store.state.bookings[0],
      itenarydays: [],
        selectedDay: {
          title: '0',
          items: []
        },
        selectedIndex: 0,
      credentials: {
        sandbox: 'Aa4-3SCsHFDvi_GYtI4wnMl_XNlj2sZE7blt62FEsslryLHYSq81TKRYdBm3FvMjlyM_5wCNM8fSM9iN'
      },
      paypal: {
        sandbox: '<sandbox client id>'
      },
      loaded: false,
      paidFor: false,
      product: {
        price: 777.77,
        description: 'leg lamp from that one movie',
        img: './assets/lamp.jpg'
      },
      paymenttoken: {},
      showPaymentForm:false,
      moment:moment,
      payments:[],
      paymentsRefreshed : false,
      showModal:false,
      message:{
                message:"",
                code:""
            },
            selectedbooking:null

    }
  },
  methods: {
    isAuth: function () {
      if (this.auth) {
        console.log('selected booking : '+ JSON.stringify(this.$store.state.selectedBooking))
      } else {
        router.push('/login')
      }
    },
    setLoaded: function () {
      this.loaded = true
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.product.description,
                  amount: {
                    currency_code: 'USD',
                    value: this.afribooking.package.price
                  }
                }
              ]
            })
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture()
            this.paidFor = true
            console.log(order)
            this.paymenttoken = order
            this.applyPayment()
          },
          onError: err => {
            console.log(err)
          }
        })
        .render(this.$refs.paypal)
    },
    // applyPayment: function () {
    //   const myFormData = new FormData()
    //   myFormData.set('booking', this.afribooking.ref)
    //   myFormData.set('amount', this.afribooking.package.price)
    //   myFormData.set('payment_ref', this.paymenttoken.id)
    //   myFormData.set('address', this.paymenttoken.payer.address.country_code)
    //   myFormData.set('payer', this.paymenttoken.payer.payer_id)
    //   myFormData.set('status', this.paymenttoken.status)
    //   axios(
    //     {
    //       method: 'post',
    //       data: myFormData,
    //       url: this.$store.state.baseUrl + '/api/payment/apply',
    //       headers: { Authorization: 'Bearer ' + this.$store.state.token }
    //     })
    //     .then((response) => {
    //       console.log('payment response : ' + response.data)
    //       this.afribooking = response.data.booking
    //     })
    //     .catch(function (error) {
    //       console.log(error)
    //     })
    // },
   
    openPaymentModal: function()
    {
      return this.showPaymentForm = true
    },
    refreshPayments:function(_items){

      console.log("Refre: "+JSON.stringify(_items));
      this.showModal = true;
      this.message.message = "Proof of payment was sent successfully"
      this.paymentsRefreshed = true;
     this.getBooking()
    },
    closeMessage:function(){
       this.message.message = ''
       this.showModal = false;
    },
    getBooking: function () {
      console.log("...................get Booking ..........................."+this.$store.state.selectedBooking.ref);

      const myFormData = new FormData()
      myFormData.set('booking', this.$store.state.selectedBooking.ref)
      axios(
        {
          method: 'post',
          url: this.baseUrl + '/api/get/booking/details',
          data:myFormData,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token','')
          
         }
        })
        .then((response) => {
          console.log('booking response' + JSON.stringify(response))
          if(response.data.error != 'undefined' && response.data.error != null)
          {
            //error
            console.log("Error: "+response.data.error);
          }
          else{
            this.selectedbooking = response.data.booking
          }
          
        })
        .catch(function (error) {
          console.log(error)
        })
    },

  },
  computed: {
    hasBooking:function(){
      return this.selectedbooking != undefined && this.selectedbooking != null
    },
    hasBookingPayments:function(){
      return (this.hasBooking  &&  this.selectedbooking.payments != undefined && this.selectedbooking.payments != null && this.selectedbooking.payments.length > 0)
    }
    //...mapState(['selectedBooking'])
  },
  created () {
    this.isAuth()
    this.getBooking()
  },
  mounted () {
   
  }
}
</script>

<style scoped>
  .paymentscontainer {
    margin-top: 10px;
  }
 .booking-container {
   display: flex;
   flex-direction: column;
   align-items: center;
 }

 .booking-header {
   height: 300px;
   width: 100%;
   object-fit: cover;
 }

  .package-name {
   margin-left: 3rem;
   margin-top: 3rem;
   color:424242;
   font-size: 1.5rem;
   font-weight: 600;
  }

  .package-details-booking-params{
    width: 30%;
    background-color: #BDBDBD;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .package-details-booking-params .total-amount {
    color:black;
    margin-bottom: 10px;
    font-size: 1.2rem;
    font-weight: 800;
    background-color: rgba(128, 128, 128, 0.494);
  }

  .package-details {
    display: flex;
    padding: 10px;
    flex-direction: row;
    width: 1000px;
  }

  .package-details-params {
    width: 70%;
  }

  .paybtn {
    padding: 10px;
    width: 200px;
    margin-top: 10px;
    border-radius:5px;
    color: #fff;
    font-weight: 600;
    background-color: #00ACC1;
  }

  .payments {
    color: #00ACC1
  }
</style>
