<template>

<nav class="bg-white pb-2.5 z-10 drop-shadow-xl">
  <div class="w-full theme_bg"></div>
  <div class="flex flex-wrap items-center justify-between mx-2">
    <router-link to="/" class="flex items-center">
        <img src="images/logo.png" class="md:h-16 mr-3 h-9" alt="Afrigetaway Logo" />
        <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white"></span>
    </router-link>
    <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-default" aria-expanded="false" @click.prevent="toggleMenu()">
      <span class="sr-only">Open main menu</span>
      <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
    </button>

    <div class="hidden w-full md:block md:w-auto">
      <ul class="flex flex-col p-2 mt-2 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
       
        <li>
          <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-yellow-700 md:p-0 active:text-yellow-600" @click.prevent="openHome()">Home</a>
        </li>
        <li v-if="isAuthenticated">
          <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-yellow-700 md:p-0 active:text-yellow-600" v-if="!loggedInUser.isAdmin" @click.prevent="openMemDashboard()">Dashboard</a>
          <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-yellow-700 md:p-0 active:text-yellow-600" v-if="loggedInUser.isAdmin" @click.prevent="openDashboard()">Dashboard</a>
        </li>
        <li>
          <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-yellow-700 md:p-0 active:text-yellow-600" @click.prevent="openAbout()">About</a>
        </li>
        <li>
          <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-yellow-700 md:p-0 active:text-yellow-600" @click.prevent="openContact()">Contact Us</a>
        </li>
        <li v-if="!isAuthenticated">
          <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-yellow-700 md:p-0 active:text-yellow-600" @click.prevent="openLogin()" >Login</a>
        </li>
        <li v-if="isAuthenticated"> 
          <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-yellow-700 md:p-0 active:text-yellow-600" @click.prevent="logout()" >Logout</a>
        </li>
        
      </ul>
    </div>
    <div v-if="showmenu" class="w-full md:none md:w-auto" id="navbar-default">
      <ul class="flex flex-col p-2 mt-2 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 text-xs md:font-medium md:border-0 md:bg-white">
        
        <li>
          <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-yellow-700 md:p-0 active:text-yellow-600" @click.prevent="openHome()">Home</a>
        </li>
        <li v-if="isAuthenticated">
          <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-yellow-700 md:p-0 active:text-yellow-600" v-if="!loggedInUser.isAdmin" @click.prevent="openMemDashboard()">Dashboard</a>
          
          <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-yellow-700 md:p-0 active:text-yellow-600" v-if="loggedInUser.isAdmin" @click.prevent="openDashboard()">Dashboard</a>
        </li>
        <li>
          <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-yellow-700 md:p-0 active:text-yellow-600" @click.prevent="openAbout()">About</a>
        </li>
        <li>
          <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-yellow-700 md:p-0 active:text-yellow-600" @click.prevent="openContact()">Contact Us</a>
        </li>
        <li v-if="!isAuthenticated">
          <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-yellow-700 md:p-0 active:text-yellow-600" @click.prevent="openLogin()" > Login</a>
        </li>
        <li v-if="isAuthenticated">
          <a href="#" class="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-yellow-700 md:p-0 active:text-yellow-600" @click.prevent="logout()" > Logout</a>
        </li>
        
      </ul>
    </div>
  </div>
</nav>

  <!-- <div class="w-screen drop-shadow-xl flex flex-col  bg-white">
    <nav class="flex flex-col">
      <div class="w-full theme_bg"></div>
      <div class="flex flex-row items-center">
        <img src="/images/logo.png" class="h-16 m-2" alt="">
      <div class="flex flex-row-reverse w-1/2 gap-6 ml-32">
        <span class="font-bold leading-4 mt-4 text-l cursor-pointer" @click="openLogin()">Login</span>
        <span class="font-bold leading-4 mt-4 text-l cursor-pointer" @click="openAbout()">About us</span>
        <span class="font-bold leading-4 mt-4 text-l cursor-pointer" @click="openHome()">Home</span>
      </div>
      </div>
     
    </nav>
  </div> -->
</template>
<script>
import router from '@/router'
import { mapGetters } from 'vuex'
export default {
  name: 'PublicNav',
  props: [
    'auth'
  ],
  data: function () {
    return {
      showmenu: false,
    }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('setLogoutAction').then(()=>{
                router.push('/')
            })
    },
    openHome: function () {
      this.showmenu = false
      router.push('/')
    },
    openMemDashboard: function () {
      this.showmenu = false
      router.push('/member/dashboard')
    },
    openDashboard: function () {
      this.showmenu = false
      router.push('/admin/dashboard')
    },
    openAbout: function () {
      this.showmenu = false
      router.push('/about')
    },
    openContact: function () {
      this.showmenu = false
      router.push('/contact')
    },
    openLogin: function () {
      this.showmenu = false
      router.push('/login')
    },
    toggleMenu: function () {
      this.showmenu = !this.showmenu
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated','loggedInUser'])
    // isAuthenticated() {
    //   //let _session = this.$store.state.token;
    //   console.log("Session: " + localStorage.getItem('access_token'));
    //   if (localStorage.getItem('access_token')) {
    //     console.log("Sesh.."+localStorage.getItem('access_token'));
    //     return true;
    //   }
    //   else {
    //     return false;
    //   }
    // }


},
 
}
</script>

<style>

</style>
