import { createApp } from 'vue'
import store from './vuex/index'
import router from './router/index'
import App from './App.vue'
import './assets/tailwind.css'
import VueLazyLoad from 'vue3-lazyload'

const app = createApp(App)
app.use(VueLazyLoad,{
    loading: 'Loading',
    error: 'Error',
    lifecycle: {
      loading: (el) => {
        console.log('loading', el)
      },
      error: (el) => {
        console.log('error', el)
      },
      loaded: (el) => {
        console.log('loaded', el)
      }
    }
  })
app.use(store)
app.use(router)

app.mount('#app')
