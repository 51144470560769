<template>
<!-- Breadcrumb Start -->
  <div class="breadcrumb-wrapper row">
    <div class="col-12 col-lg-3 col-md-6">
      <h4 class="page-title">{{title}}</h4>
    </div>
    <div class="col-12 col-lg-9 col-md-6">
      <ol class="breadcrumb float-right">
        <li><a href="index.html">Admin</a></li>
        <li class="active">/ {{title}}</li>
      </ol>
    </div>
  </div>
<!-- Breadcrumb End -->
</template>
<script>
export default {
  name: 'BreadCrumb',
  props: ['title']
}
</script>

<style>

</style>
