<template>
  <div class="grid grid-cols-3 p-5 rounded shadow gap-2 my-3 bg-white">
    <!-- <img class="packageimage" :src="baseUrl + '/media/get/' + bookingitem.package.headerimage.ref" alt=""> -->
    <div class="packagename">{{ useritem.name }}</div>
    <div class="packageemail">{{ useritem.email }}</div>
    <div class="packageemail">{{ useritem.role.name }}</div>
  </div>
</template>

<script>
export default {
  name: 'UsersList',
  props: ['useritem'],
  data: function () {
    return {
      baseUrl: this.$store.state.baseUrl
    }
  }
}
</script>

<style>
.package-row {
  display: grid;
  grid-template-columns: 50px 150px 1fr;
  background: #ECEFF1;
  width: 100%;
  padding: 10px;
  height: 70px;
  border-radius: 5px;
  gap: 10px;
  box-shadow: 1px 2px 1px -2px rgba(0,0,0,0.48);
  -webkit-box-shadow: 1px 2px 1px -2px rgba(0,0,0,0.48);
  -moz-box-shadow: 1px 1px 2px -2px rgba(0,0,0,0.48);
}

.packageimage {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.packagename {
  font-weight: 600;
  color: #2196F3;
}

</style>
