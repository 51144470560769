<template>
  <div class="stat-container">
    <div class="title">{{stat.title}}</div>
    <div class="data-container">{{stat.data}}</div>
  </div>
</template>
<script>
export default {
  name: 'AdminNav',
  data: function () {
    return {
    }
  },
  props: ['stat']
}
</script>

<style scoped>
.stat-container {
  width: 30%;
  height: 15vh;
  padding: 5px;
  background: #EEEEEE;
  border-radius: 5px;
}
.title {
 color: rgb(0, 0, 0);
 font-weight: 600;
 font-size: 18px;
}
</style>
