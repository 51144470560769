<template>
  <div class="pagecontainer bg-white">
    <div class="adminbody">
      <sidenav active="Media"></sidenav>
      <div class="pagecontent">
        <div class="page-title">Media</div>
        <div class="toolbox">
          <input type="file" @change="onFileChange"/>
        </div>
        <div class="flex flex-wrap w-full overflow-auto p-4 mb-4 h-5/6 bg-slate-50">
          <medialist :imageitem=item v-for="item in images" :key="item.ref"></medialist>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import adminheader from '@/components/admin/header.vue'
import axios from 'axios'
import Sidenav from '@/components/sidenav.vue'
import medialist from '@/views/Admin/Media/list.vue'
import router from '@/router'

export default {
  name: 'MediaDashboard',
  components: {
    adminheader,
    Sidenav,
    medialist
  },
  data: function () {
    return {
      images: [],
      baseUrl: this.$store.state.baseUrl,
      url: this.$store.state.baseUrl + '/api/media/create',
      headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + this.$store.state.token },
      filesUploaded: [],
      selectedFile: {}
    }
  },
  methods: {
    getMedia: function () {
      axios(
        {
          method: 'post',
          url: this.$store.state.baseUrl + '/api/admin/media',
          headers: { Authorization: 'Bearer ' + this.$store.state.token }
        })
        .then((response) => {
          console.log(response)
          this.images = response.data.media
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    openNewBlog: function () {
      router.push('/admin/blog/new')
    },
    onFileChange (event) {
      this.selectedFile = event.target.files[0]
      this.uploadFile()
    },
    onSuccess () {
      this.getMedia()
    },
    uploadFile () {
      const myFormData = new FormData()
      myFormData.set('name', this.selectedFile.name)
      myFormData.append('image', this.selectedFile, this.selectedFile.name)
      axios(
        {
          method: 'post',
          url: this.baseUrl + '/api/media/create',
          data: myFormData,
          headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + this.token }
        })
        .then((response) => {
          console.log(response)
          this.getMedia()
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  created () {
    this.getMedia()
  }
}
</script>
<style scoped>
.pagecontainer {
    display: flex;
    flex-direction: column;
  }

  .btn-open-package {
    background-color: #4FC3F7;
    color: #fff;
    font-weight: 700;
    padding: 10px 10px;
    border-radius: 5px;
  }
  .adminbody {
    display: grid;
    grid-template-columns: 250px 1fr;
  }

  .pagecontent {
    padding: 10px;
    display: flex;
    max-height: 80vh;
    overflow: auto;
    flex-direction: column;
  }

  .page-title {
    padding: 20px;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .page-list {
    width: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .toolbox {
    width: 100%;
    background: #9E9E9E;
    height: 10vh;
    padding: 2vh;
    display: grid;
    grid-template-columns: 200px 1fr;
  }

  .newbutton {
    background: #00ACC1;
    color: #fff;
    font-weight: 600;
    width: 150px;
    padding: 10px;
    border-radius: 5px;
  }
</style>
