<template>
  <div class="container mx-auto">
    <div class="flex flex-col w-full h-screen">
      <BackButtonComponent to="/list/packages"></BackButtonComponent>
      <Suspense>
        <template #default>
          <div class="flex flex-col md:flex-row  bg-white rounded mt-4 overflow-y-auto h-5/6 p-2 mb-20" >
        
        <div class="flex flex-col p-2 md:w-1/2">
          
          <div class="flex flex-col">
            <img class="w-full rounded bg-slate-200 max-h-full" alt="package header" v-lazy="{ src: mediaUrl+''+tpackage.headerimage.ref, loading: '/images/loader.gif', error: '/images/loader.gif' }"
               />
          </div>
          <div class="flex flex-col justify-center ">
            <!-- <div class="flex-wrap gap-4 justify-center mt-8 hidden md:flex">
              <img class="w-full rounded bg-slate-200 max-h-full" v-lazy="{ src:mediaUrl+  item.ref,loading: '/images/loader.gif', error: '/images/loader.gif' }"
                v-for="item in packagegallery.slice(0, 4)" :key="item.ref" alt=""/>
                </div> -->
              <div
                class="text-orange-300 cursor-pointer font-bold flex w-full justify-center items-center gap-4 my-2 hover:scale-110 duration-500"
                @click.prevent="showModal = true" v-if="packagegallery.length > 4">
                <svg xmlns="http://www.w3.org/2000/svg" width="37.393" height="37.634" viewBox="0 0 37.393 37.634">
                  <g id="Group_1420" data-name="Group 1420" transform="translate(0 37.634)">
                    <path id="Path_11794" data-name="Path 11794"
                      d="M26.071-31.122A17.057,17.057,0,0,0,10.7-28.848c-4.257,3.1-5.307,8.283-4.431,13.228C7.786-7.049,17.151-4.4,24.468-7.669c3.617-1.619,5.718-5.5,6.073-9.348.39-4.206-3.353-7.873-7.12-9.145-4.5-1.52-8.464.656-10.068,4.986s.867,7.9,5.507,8.155A4.654,4.654,0,0,0,23.608-16.5c.509-2.348-2.229-3.863-3.993-4.536-1.434-.547-2.049,1.752-.631,2.293a5.959,5.959,0,0,1,1.414.707q1.087,2.186-.7,2.535a4.18,4.18,0,0,1-3.1-.519c-1.957-1-1.579-3.09-.845-4.756,1.6-3.624,5.258-4.347,8.584-2.38a11.3,11.3,0,0,1,3.358,3.328c1.075,1.648.3,3.706-.26,5.378-.988,2.962-3.154,4.6-6.118,5.4a12.568,12.568,0,0,1-8.083-.6c-3.1-1.344-4.225-4.082-4.785-7.24C6.7-26.728,17.277-31.475,25.44-28.829,26.9-28.356,27.524-30.651,26.071-31.122Z"
                      fill="#f6b45e" />
                    <path id="Path_11795" data-name="Path 11795" d="M18.919-33.594H23.56l-2.321-4.04Z" fill="#f6b45e" />
                    <path id="Path_11796" data-name="Path 11796" d="M11.851-31.632l4.407-1.451-3.469-3.112Z"
                      fill="#f6b45e" />
                    <path id="Path_11797" data-name="Path 11797" d="M6.566-27.485,9.909-30.7,5.437-32.006Z"
                      fill="#f6b45e" />
                    <path id="Path_11798" data-name="Path 11798" d="M3.8-21.343l1.415-4.419-4.556.977Z" fill="#f6b45e" />
                    <path id="Path_11799" data-name="Path 11799" d="M4.279-14.67l-.528-4.61L0-16.516Z" fill="#f6b45e" />
                    <path id="Path_11800" data-name="Path 11800" d="M7.763-8.328,5.22-12.209,3.112-8.054Z"
                      fill="#f6b45e" />
                    <path id="Path_11801" data-name="Path 11801" d="M13.2-4.269,9.033-6.314l.3,4.65Z" fill="#f6b45e" />
                    <path id="Path_11802" data-name="Path 11802" d="M20.953-4.151l-4.634.231L18.838,0Z" fill="#f6b45e" />
                    <path id="Path_11803" data-name="Path 11803" d="M28.011-7.043l-4.048,2.27,4,2.389Z" fill="#f6b45e" />
                    <path id="Path_11804" data-name="Path 11804" d="M32.444-12.386,29.862-8.531l4.648.322Z"
                      fill="#f6b45e" />
                    <path id="Path_11805" data-name="Path 11805" d="M33.517-19.9l-.311,4.628,4.187-2.044Z"
                      fill="#f6b45e" />
                    <path id="Path_11806" data-name="Path 11806" d="M29.34-26.551l2.677,3.791,1.963-4.225Z"
                      fill="#f6b45e" />
                  </g>
                </svg>
                <span class="text-shadow">More Images</span>

              </div>
             

              <transition name="modal" mode="out-in">
                <the-modal-component v-if="showModal" @close="showModal = false">
                  <template v-slot:body>
                    <ImageCarousel :items="packagegallery">
                      <!-- <slide v-for="(item, index) of packagegallery" :key="index">
                        <img class="w-full h-full object-cover" :src="baseUrl + '/media/get/' + item.ref" />
                      </slide>
                      <template #addons>
                        <navigation />
                        <pagination />
                      </template> -->
                    </ImageCarousel>
                  </template>
                </the-modal-component>
              </transition>
            
          </div>
        </div>
        <div class="flex flex-col p-2 md:w-1/2">
          <div class="border-b border-gray-200 pb-6">
            <h1 class="lg:text-2xl text-xl font-semibold lg:leading-6 leading-7 text-gray-800 mt-2">{{ tpackage.name }}
            </h1>
          </div>
          <div class="py-4 border-b border-gray-200 flex items-center justify-between">
            <p class="text-base leading-4 text-gray-600 ">Price</p>
            <div class="flex items-center justify-center">
              <p v-if="tpackage.availability != 'local'" class="text-sm leading-none text-gray-600 mr-3">USD {{ tpackage.intl_price }}</p>
              <p v-else class="text-sm leading-none text-gray-600 mr-3">BWP {{ tpackage.local_price }}</p>
            </div>
          </div>
          <div class="border-b border-gray-200 pb-6">
            <p class="text-base lg:leading-tight leading-normal text-gray-600 mt-7 whitespace-pre-line">{{ tpackage.description }}
            </p>
          </div>
        <div class="flex flex-col justify-center">
            <IternaryDays type="carousel" />
        </div>

        <div class="flex flex-row justify-center mt-4">
          <input class="mx-2 ring-afyellow-normal text-afyellow-normal checked:bg-afyellow-normal border-afyellow-normal focus:ring-afyellow-normal h-6 w-6"
                                              type="checkbox" v-model="terms"  />
                                        <label class="text-sm block ml-2">I agree to the Afrigetaway's<span  class=" text-yellow-600 px-1.5 text-center hover:underline cursor-pointer" @click="toggleUserPolicy()">
                                                Use Policy & Terms &
                                            Conditions
                                        </span>
                                      </label>

                <transition name="modal" mode="out-in">
                <the-modal-component v-if="showUserPolicy" @close="showUserPolicy = false">
                  <template v-slot:header>
                    <div class="text-xl font-bold">Afrigetaway Terms and Conditions</div>
                  </template>
                  <template v-slot:body>
                    <div class="font-bold">Booking Procedure, Terms &amp; Conditions</div>  
                    <div class="text-justify">
                      <p>A 20% &#160;non-refundable deposit is required to confirm and guarantee the booking.</p>
                      <p>Should you prefer to pay by credit card we will send you an email with card payment link within one (1) working day.</p><p> Any short payment resulting from bank charges and/or exchange rate fluctuations will be charged back to guest/client.</p><p> Final balance payment is required eight (8) weeks prior arrival date. Once the full payment is received and cleared in our bank account, we will send you a confirmation of booking and issue a travel voucher. </p>
                      
                    </div>
                    <br>
                    <div class="text-justify">
                      <p class="font-bold">Cancellations Terms &amp; Conditions</p>
                      <p>Cancellations are aligned to suppliers&rsquo; cancellation policies, please request for the same upon confirmation of booking.</p>
                       <p>All cancellations must be received in writing at <span class="text-afpinkred-normal underline">bkeimetswe@afrigetaway.com&#160;</span></p>
                       <br>
                       <p class="font-bold">Cancellation fees will be imposed as follows;</p> 
                       <br>
                        <p>Cancellations 60 days prior to arrival date will receive a full refund of all monies paid less a 10% administration fee and local bank charges.</p> <p>Cancellations between 59 and 30 days prior travel will be refunded 50% of all monies paid less local bank charges.</p>
                       <p>No refunds will be given for cancellations less than 30 days prior travel or no-shows; guest/ client will be liable for the full amount due.</p>

                    </div>
                  </template>
                </the-modal-component>
              </transition>
        </div>
          <div   class="flex flex-col md:flex-row justify-between mb-8 p-4">
            <a v-if="tpackage.packagefile" :href="this.$store.state.baseUrl + '/media/download/' + tpackage.packagefile.ref"  target="_blank" download class="focus:ring-2 focus:ring-offset-2
                focus:ring-gray-800 text-base flex items-center justify-center leading-none
                text-white bg-gray-800 w-full p-4 hover:bg-gray-700 focus:outline-none mt-4 mx-1">
              <svg class="mr-3 text-white w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25" />
              </svg>Download This Package
            </a>
            <button @click="openBookingForm()" class="focus:ring-2 focus:ring-offset-2
              focus:ring-gray-800 text-base flex items-center justify-center leading-none
              text-white bg-gray-800 w-full p-4 hover:bg-gray-700 focus:outline-none mt-4 mx-1" :class="terms ? '':' disabled:opacity-75 disabled:cursor-not-allowed'" :disabled="!terms">
              <svg class="mr-3 text-white w-6 h-6" viewBox="0 0 16 17" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.02301 7.18999C7.48929 6.72386 7.80685 6.12992 7.93555 5.48329C8.06425 4.83666 7.9983 4.16638 7.74604 3.55724C7.49377 2.94809 7.06653 2.42744 6.51835 2.06112C5.97016 1.6948 5.32566 1.49928 4.66634 1.49928C4.00703 1.49928 3.36252 1.6948 2.81434 2.06112C2.26615 2.42744 1.83891 2.94809 1.58665 3.55724C1.33439 4.16638 1.26843 4.83666 1.39713 5.48329C1.52583 6.12992 1.8434 6.72386 2.30968 7.18999L4.66634 9.54749L7.02301 7.18999Z"
                  stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.66699 4.83333V4.84166" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M13.69 13.8567C14.1563 13.3905 14.4738 12.7966 14.6025 12.15C14.7312 11.5033 14.6653 10.8331 14.413 10.2239C14.1608 9.61476 13.7335 9.09411 13.1853 8.72779C12.6372 8.36148 11.9926 8.16595 11.3333 8.16595C10.674 8.16595 10.0295 8.36148 9.48133 8.72779C8.93314 9.09411 8.5059 9.61476 8.25364 10.2239C8.00138 10.8331 7.93543 11.5033 8.06412 12.15C8.19282 12.7966 8.51039 13.3905 8.97667 13.8567L11.3333 16.2142L13.69 13.8567Z"
                  stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.333 11.5V11.5083" stroke="currentColor" stroke-width="1.25" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              Book Your Place
          </button>
        </div>
      </div>

     
    </div>
        </template>
        <template #fallback>
          <div>
            <p>Please wait while loading...</p>
          </div>
        </template>
      </Suspense>
     
  </div>
</div></template>
<script>
import axios from 'axios'
import router from '@/router'
import ImageCarousel from '../components/public/ImageCarousel.vue'
import TheModalComponent from '../components/public/popUpModal.vue'
import Vue3Html2pdf from 'vue3-html2pdf'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import BackButtonComponent from '../components/backButtonComponent.vue'
import IternaryDays from '../components/public/Packages/IternaryDays.vue'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'HomeBookings',
  components: {
    Vue3Html2pdf,
    TheModalComponent,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    ImageCarousel,
    BackButtonComponent,IternaryDays
},
  data: function () {
    return {
      baseUrl: this.$store.state.baseUrl,
      token: this.$store.state.token,
      mediaUrl:this.$store.state.baseUrl+'/media/get/',
      tpackage: {
        headerimage: {
          ref: ''
        }
      },
      showpackage: false,
      showbookingForm: true,
      itenarydays: [],
      selectedDay: {
        title: '0',
        items: []
      },
      selectedIndex: 0,
      packagegallery: [],
      showModal:false,
      moment: moment,
      terms:false,
      showUserPolicy:false
    }
  },
  methods: {
    getpackage: function () {
      axios(
        {
          method: 'post',
          url: this.baseUrl + '/api/get/package/' + this.selectedTravelPackage.ref
        })
        .then((response) => {
          console.log(response.data.package)
          this.tpackage = response.data.package
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    placeBooking: function () {
      console.log('token : ' + this.tpackage.ref)
      const myFormData = new FormData()
      myFormData.set('package', this.tpackage.ref)
      axios(
        {
          method: 'post',
          data: myFormData,
          url: this.baseUrl + '/api/booking/create',
          headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + this.token }
        })
        .then((response) => {
          console.log(response)
          router.push('/member/dashboard')
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    openBookingForm: function () {
      router.push('/booking/complete/' + this.tpackage.ref)
    },
    openMoreImages: function () {
    },
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    hasGenerated() {
      alert("PDF generated successfully!");
    },
    toggleUserPolicy(){
      this.showUserPolicy = !this.showUserPolicy
    },
    async generatePDF(item) {
      //fetch();

      try {
        console.log("REF: " + item.ref);
        const url = this.$store.state.baseUrl + '/media/download/' + item.ref
        const data = await fetch(
          url,
        {headers: { 'Content-Type': 'application/json'}
      })
        const blob = await data.blob()
        const objectUrl = URL.createObjectURL(blob)

        const link = document.createElement('a')

        link.setAttribute('href', objectUrl)
        link.setAttribute('download', filename)
        link.style.display = 'none'

        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)

      }
      catch (err) {
        alert("Failed to download file!");
        console.log(JSON.stringify(err))

      }
    },
    getItenaryDays: function () {
      const myFormData = new FormData()
      myFormData.set('package', this.tpackage.ref)

      axios(
        {
          method: 'post',
          data: myFormData,
          url: this.$store.state.baseUrl + '/api/admin/package/get/itenarydays',
          headers: { 
            'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + this.$store.state.token,
            'Access-Control-Allow-Origin': '*' }
        })
        .then((response) => {
          console.log(response.data.itenarydays)
          this.itenarydays = response.data.itenarydays
          this.selectedDay = this.itenarydays[this.selectedIndex]
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    fn_nextDay: function () {
      if (this.selectedIndex < this.itenarydays.length) {
        this.selectedIndex = this.selectedIndex + 1
        this.selectedDay = this.itenarydays[this.selectedIndex]
      }
      console.log('click: ' + this.selectedIndex)
    },
    fn_prevDay: function () {
      if (this.selectedIndex > 0) {
        this.selectedIndex = this.selectedIndex - 1
        this.selectedDay = this.itenarydays[this.selectedIndex]
      }
      console.log('click: ' + this.selectedIndex)
    },
    fn_getpackagemedia: function () {
      const myFormData = new FormData()
      myFormData.set('package', this.$route.params.packageref)
      axios({
        method: 'post',
        data: myFormData,
        url: this.$store.state.baseUrl + '/api/admin/get/package/media',
        headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + this.$store.state.token }
      }).then((response) => {
        this.packagegallery = response.data.gallery
        console.log('>>> Returned Package Gallery.')
        console.log(response.data.gallery)
      }).catch(function (error) {
        console.log(error)
      })
    }
  },
  computed:{
    ...mapState(['selectedTravelPackage']),

    hasSelectedTravelPackage(){
      return typeof this.selectedTravelPackage != 'undefined' && this.selectedTravelPackage != null
    }
  },
  created () {
    if(this.hasSelectedTravelPackage)
    {
      this.getpackage()
    }
    
    this.fn_getpackagemedia()
  }
}
</script>
<style scoped>
  #booking {
    display:flex;
    justify-content: flex-start;
    width:100%;
    min-height: 80vh;
  }
  .package-view-header-img {
    height: 400px;
    width: 100%;
    object-fit: cover;
  }

  .package-view {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .package-details {
    margin-top: 2vh;
    margin: 3%;
    width: 94%;
  }

  .itenary {
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
  }

  .itenary .details {
    width:50%;
  }

  .itenary .gallery {
    width:50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: scroll;
    height: 50vh;
  }

  .itenary .booking-form {
    width: 100%;
  }

  .itenary .gallery img{
    max-height: 150px;
    max-width: 200px;
  }

  .p-header-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content:start;
    align-items: center;
  }

  .booking-btn {
    width: 40%;
    color: white;
    background-color: #00ACC1;
    border-radius: 5px;
    padding: 5px;
  }

  .moreinfo-btn {
    width: 40%;
    color: #00ACC1;
    border: 2px solid #00ACC1;
    border-radius: 5px;
    padding: 5px;
    margin-left: 5px;
  }

  .btn-container {
    display: flex;
    width:100%;
    /* width: 600px; */
  }

  .pricing {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .title-disc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    text-align: left;
  }
</style>
