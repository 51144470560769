<template>
  <div class="container md:mx-auto m-4 h-screen">
    <div class="flex flex-col h-screen w-full">
      <h1 class="font-bold text-xl text-afgreen-normal">Dashboard</h1>
     
      <div class="flex flex-col h-screen overflow-y-auto px-2 pb-8 mb-8 rounded">
        <div class="p-1 flex flex-row gap-8 my-2">
        <span class="text-lg text-aforange-normal p-2">My Bookings</span>
      </div>
        <bookings></bookings>
      </div>
       
    </div>
  </div>
</template>
<script>
import bookings from '@/components/public/bookings'
export default {
  name: 'MemberDashboard',
  components: {
    bookings
  },
  data: function () {
    return {
    }
  }
}
</script>
<style scoped>
  .dash-content {
    min-height: 80vh;
  }

  .r-card {
    background: #ffffff;
    box-shadow: 2px 1px 4px 0px rgba(117,117,117 ,0.6);
    -webkit-box-shadow: 2px 1px 4px 0px rgba(117,117,117 ,0.6);
    -moz-box-shadow: 2px 1px 4px 0px rgba(117,117,117 ,0.6);
    border-radius: 10px;
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
  .bg-page {
    height: 100vh;
    width: 100%;
  }

  .nav {
    margin-top: 20px;
  }
</style>
