<template>
<div class="flex flex-col shadow p-4 gap-2">
  <div @click="openLink(item.address)" class="sidenav-item hover:scale-110 cursor-pointer duration-500" v-bind:class="{ 'sidenav-item-active': activeLink(item.title) }" v-for="item in links" :key="item.title">
    <span class="icon-holder">
      <i class="lni" v-bind:class="item.icon"></i>
    </span>
    <span class="title">
      {{item.title}}
    </span>
    <span class="arrow">
      <i class="lni lni-chevron-right"></i>
    </span>
  </div>
</div>
</template>
<script>
import router from '@/router'
export default {
  name: 'SideNav',
  data: function () {
    return {
      links: [
        {
          title: 'Dashboard',
          icon: 'lni-home',
          address: '/admin/dashboard'
        },
        {
          title: 'Packages',
          icon: 'lni-package',
          address: '/admin/packages'
        },
        {
          title: 'Bookings',
          icon: 'lni-bookmark',
          address: '/admin/bookings'
        },
        {
          title: 'Users',
          icon: 'lni-users',
          address: '/admin/users'
        },
        {
          title: 'Media',
          icon: 'lni-gallery',
          address: '/admin/media'
        },
        {
          title: 'Destinations',
          icon: 'lni-map',
          address: '/admin/destinations'
        },
        {
          title: 'Blogs',
          icon: 'lni-book',
          address: '/admin/blog'
        }
      ]
    }
  },
  props: ['active'],
  methods: {
    openLink: (link) => {
      router.push(link)
    },
    activeLink: function (link) {
      return this.active === link
    }
  },
  computed: {
  }
}

</script>

<style>

.sidenav-container {
  background:#F5F5F5;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 5px;
}

.sidenav-item {
  color: #212121;
  font-size: 0.9rem;
  display: grid;
  grid-template-columns: 40px 1fr 20px;
  padding: 2px 5px;
}

.sidenav-item-active {
  color: #fff;
  font-size: 0.9rem;
  display: grid;
  grid-template-columns: 40px 1fr 20px;
  padding: 2px 5px;
  background: #C62828;
  border-radius: 5px;
  -webkit-box-shadow: 2px 2px 4px -2px #000000;
  box-shadow: 2px 2px 4px -2px #000000;
}
</style>
