<template>
  <div class="pagecontainer bg-white">
    <div class="adminbody">
      <sidenav active="Users"></sidenav>
      <div class="pagecontent">
        <div class="page-title">Users</div>
        <span class="m-2 bg-teal-400 text-white w-32 rounded shadow p-2">Invite Admin</span>
        <div class="w-full overflow-auto h-4/5 bg-slate-50">
          <userslist :useritem=item v-for="item in users" :key="item.ref"></userslist>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import adminheader from '@/components/admin/header.vue'
import axios from 'axios'
import Sidenav from '@/components/sidenav.vue'
import userslist from '@/views/Admin/Users/list.vue'

export default {
  name: 'BookingsDashboard',
  components: {
    adminheader,
    Sidenav,
    userslist
  },
  data: function () {
    return {
      users: []
    }
  },
  methods: {
    getbookings: function () {
      axios(
        {
          method: 'post',
          url: this.$store.state.baseUrl + '/api/get/users',
          headers: { Authorization: 'Bearer ' + this.$store.state.token }
        })
        .then((response) => {
          console.log(response)
          this.users = response.data.data
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  created () {
    this.getbookings()
  }
}
</script>
<style scoped>
.pagecontainer {
    display: flex;
    flex-direction: column;
  }

  .btn-open-package {
    background-color: #4FC3F7;
    color: #fff;
    font-weight: 700;
    padding: 10px 10px;
    border-radius: 5px;
  }
  .adminbody {
    display: grid;
    grid-template-columns: 250px 1fr;
  }

  .pagecontent {
    padding: 10px;
    display: flex;
    max-height: 80vh;
    overflow: auto;
    flex-direction: column;
  }

  .page-title {
    padding: 20px;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .page-list {
    width: 800px;
  }
</style>
