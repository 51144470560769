<template>
<!-- Footer START -->
<footer class="content-footer">
  <div class="footer">
    <div class="copyright">
      <span>powered by <b class="text-dark">PCG Software</b>. All Right Reserved</span>
    </div>
  </div>
</footer>
<!-- Footer END -->
</template>
<script>
export default {
  name: 'AdminFooter'
}
</script>

<style>

.content-footer {
  background-color: #fff;
}

</style>
