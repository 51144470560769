<template>
    <div>

        <transition name="modal" mode="out-in">
                <the-modal-component v-if="dangerzone" @close="dangerzone = false">
                  <template v-slot:header>
                    <p>Logging Out</p>
                  </template>
                  <template v-slot:body>
                 <div class="flex flex-col justify-center px-3">
                  <p>{{message.message}}</p>
                  
                 </div>
                  </template>
                </the-modal-component>
              </transition>
       
        
    </div>
</template>
<script>
import router from '@/router'
import TheModalComponent from './public/popUpModal.vue'
export default {
    name:"Logout",
    components:{
        TheModalComponent
},
    data(){
        return{
            events:['mousemove','click','mousedown','keypress','scroll','load'],
            warningTimer:null,
            logoutTimer:null,
            dangerzone:false,
            message:{
                message:"",
                code:""
            }
        }
       

    },
    methods:{
        resetTimer:function(){

            clearTimeout(this.warningTimer)
            clearTimeout(this.logoutTimer)
            
            this.setTimers()
        },
        setTimers:function(){

            this.warningTimer = setTimeout(this.warningMessage,25 * 60 * 1000)
            this.logoutTimer = setTimeout(this.logoutUser,30 * 60 * 1000)
            this.dangerzone = false
            this.message.message = ""
        },
        warningMessage:function(){
            this.dangerzone = true
            this.message.message = "Are you still with us? \n Press any key to confirm"
        },
        logoutUser:function(){
            this.$store.dispatch('setLogoutAction').then(()=>{

                router.push('/')
            })
      

        }
    },
    mounted(){
        this.events.forEach(event => {
            addEventListener(event,this.resetTimer)
        },this);

        this.setTimers()
    },
    unmounted(){
        this.events.forEach(event => {
            removeEventListener(event,this.resetTimer)
        },this);
        this.resetTimer()
    }
}
</script>