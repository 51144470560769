<template>
  
  <div class="container mx-auto">
    <div class="overflow-y-auto h-screen">
      <div class="flex flex-col items-center">
      <div class="font-bold text-2xl py-4">
      About Us
      </div>
    <div class="flex flex-col justify-center items-center gap-2 h-full  whitespace-pre-line text-center pb-8 px-4 md:pb-10">
      <p>The founders/Directors of this organization are indigenous youth Batswana whom have the passion and
vision of unlocking the destinations potential through creating creative trails which supports local
communities and using creative tourism as a tool for economic growth and diversity.</p>
<svg xmlns="http://www.w3.org/2000/svg" width="37.393" height="37.634" viewBox="0 0 37.393 37.634">
            <g id="Group_1420" data-name="Group 1420" transform="translate(0 37.634)">
              <path id="Path_11794" data-name="Path 11794" d="M26.071-31.122A17.057,17.057,0,0,0,10.7-28.848c-4.257,3.1-5.307,8.283-4.431,13.228C7.786-7.049,17.151-4.4,24.468-7.669c3.617-1.619,5.718-5.5,6.073-9.348.39-4.206-3.353-7.873-7.12-9.145-4.5-1.52-8.464.656-10.068,4.986s.867,7.9,5.507,8.155A4.654,4.654,0,0,0,23.608-16.5c.509-2.348-2.229-3.863-3.993-4.536-1.434-.547-2.049,1.752-.631,2.293a5.959,5.959,0,0,1,1.414.707q1.087,2.186-.7,2.535a4.18,4.18,0,0,1-3.1-.519c-1.957-1-1.579-3.09-.845-4.756,1.6-3.624,5.258-4.347,8.584-2.38a11.3,11.3,0,0,1,3.358,3.328c1.075,1.648.3,3.706-.26,5.378-.988,2.962-3.154,4.6-6.118,5.4a12.568,12.568,0,0,1-8.083-.6c-3.1-1.344-4.225-4.082-4.785-7.24C6.7-26.728,17.277-31.475,25.44-28.829,26.9-28.356,27.524-30.651,26.071-31.122Z" fill="#f6b45e"/>
              <path id="Path_11795" data-name="Path 11795" d="M18.919-33.594H23.56l-2.321-4.04Z" fill="#f6b45e"/>
              <path id="Path_11796" data-name="Path 11796" d="M11.851-31.632l4.407-1.451-3.469-3.112Z" fill="#f6b45e"/>
              <path id="Path_11797" data-name="Path 11797" d="M6.566-27.485,9.909-30.7,5.437-32.006Z" fill="#f6b45e"/>
              <path id="Path_11798" data-name="Path 11798" d="M3.8-21.343l1.415-4.419-4.556.977Z" fill="#f6b45e"/>
              <path id="Path_11799" data-name="Path 11799" d="M4.279-14.67l-.528-4.61L0-16.516Z" fill="#f6b45e"/>
              <path id="Path_11800" data-name="Path 11800" d="M7.763-8.328,5.22-12.209,3.112-8.054Z" fill="#f6b45e"/>
              <path id="Path_11801" data-name="Path 11801" d="M13.2-4.269,9.033-6.314l.3,4.65Z" fill="#f6b45e"/>
              <path id="Path_11802" data-name="Path 11802" d="M20.953-4.151l-4.634.231L18.838,0Z" fill="#f6b45e"/>
              <path id="Path_11803" data-name="Path 11803" d="M28.011-7.043l-4.048,2.27,4,2.389Z" fill="#f6b45e"/>
              <path id="Path_11804" data-name="Path 11804" d="M32.444-12.386,29.862-8.531l4.648.322Z" fill="#f6b45e"/>
              <path id="Path_11805" data-name="Path 11805" d="M33.517-19.9l-.311,4.628,4.187-2.044Z" fill="#f6b45e"/>
              <path id="Path_11806" data-name="Path 11806" d="M29.34-26.551l2.677,3.791,1.963-4.225Z" fill="#f6b45e"/>
            </g>
          </svg>
      <p>
        Just send us an email detailing the destinations, activities and dates and we will get back to you with a
range of options to suit your budget. Sit back, relax and we’ll take care of all the details so you can
simply enjoy your holiday.
      </p>

      <svg xmlns="http://www.w3.org/2000/svg" width="37.393" height="37.634" viewBox="0 0 37.393 37.634">
            <g id="Group_1420" data-name="Group 1420" transform="translate(0 37.634)">
              <path id="Path_11794" data-name="Path 11794" d="M26.071-31.122A17.057,17.057,0,0,0,10.7-28.848c-4.257,3.1-5.307,8.283-4.431,13.228C7.786-7.049,17.151-4.4,24.468-7.669c3.617-1.619,5.718-5.5,6.073-9.348.39-4.206-3.353-7.873-7.12-9.145-4.5-1.52-8.464.656-10.068,4.986s.867,7.9,5.507,8.155A4.654,4.654,0,0,0,23.608-16.5c.509-2.348-2.229-3.863-3.993-4.536-1.434-.547-2.049,1.752-.631,2.293a5.959,5.959,0,0,1,1.414.707q1.087,2.186-.7,2.535a4.18,4.18,0,0,1-3.1-.519c-1.957-1-1.579-3.09-.845-4.756,1.6-3.624,5.258-4.347,8.584-2.38a11.3,11.3,0,0,1,3.358,3.328c1.075,1.648.3,3.706-.26,5.378-.988,2.962-3.154,4.6-6.118,5.4a12.568,12.568,0,0,1-8.083-.6c-3.1-1.344-4.225-4.082-4.785-7.24C6.7-26.728,17.277-31.475,25.44-28.829,26.9-28.356,27.524-30.651,26.071-31.122Z" fill="#f6b45e"/>
              <path id="Path_11795" data-name="Path 11795" d="M18.919-33.594H23.56l-2.321-4.04Z" fill="#f6b45e"/>
              <path id="Path_11796" data-name="Path 11796" d="M11.851-31.632l4.407-1.451-3.469-3.112Z" fill="#f6b45e"/>
              <path id="Path_11797" data-name="Path 11797" d="M6.566-27.485,9.909-30.7,5.437-32.006Z" fill="#f6b45e"/>
              <path id="Path_11798" data-name="Path 11798" d="M3.8-21.343l1.415-4.419-4.556.977Z" fill="#f6b45e"/>
              <path id="Path_11799" data-name="Path 11799" d="M4.279-14.67l-.528-4.61L0-16.516Z" fill="#f6b45e"/>
              <path id="Path_11800" data-name="Path 11800" d="M7.763-8.328,5.22-12.209,3.112-8.054Z" fill="#f6b45e"/>
              <path id="Path_11801" data-name="Path 11801" d="M13.2-4.269,9.033-6.314l.3,4.65Z" fill="#f6b45e"/>
              <path id="Path_11802" data-name="Path 11802" d="M20.953-4.151l-4.634.231L18.838,0Z" fill="#f6b45e"/>
              <path id="Path_11803" data-name="Path 11803" d="M28.011-7.043l-4.048,2.27,4,2.389Z" fill="#f6b45e"/>
              <path id="Path_11804" data-name="Path 11804" d="M32.444-12.386,29.862-8.531l4.648.322Z" fill="#f6b45e"/>
              <path id="Path_11805" data-name="Path 11805" d="M33.517-19.9l-.311,4.628,4.187-2.044Z" fill="#f6b45e"/>
              <path id="Path_11806" data-name="Path 11806" d="M29.34-26.551l2.677,3.791,1.963-4.225Z" fill="#f6b45e"/>
            </g>
          </svg>

      <p>
        We arrange travel, tour packages and accommodation for corporate, groups and individuals in and
outside Botswana.
      </p>

      <svg xmlns="http://www.w3.org/2000/svg" width="37.393" height="37.634" viewBox="0 0 37.393 37.634">
            <g id="Group_1420" data-name="Group 1420" transform="translate(0 37.634)">
              <path id="Path_11794" data-name="Path 11794" d="M26.071-31.122A17.057,17.057,0,0,0,10.7-28.848c-4.257,3.1-5.307,8.283-4.431,13.228C7.786-7.049,17.151-4.4,24.468-7.669c3.617-1.619,5.718-5.5,6.073-9.348.39-4.206-3.353-7.873-7.12-9.145-4.5-1.52-8.464.656-10.068,4.986s.867,7.9,5.507,8.155A4.654,4.654,0,0,0,23.608-16.5c.509-2.348-2.229-3.863-3.993-4.536-1.434-.547-2.049,1.752-.631,2.293a5.959,5.959,0,0,1,1.414.707q1.087,2.186-.7,2.535a4.18,4.18,0,0,1-3.1-.519c-1.957-1-1.579-3.09-.845-4.756,1.6-3.624,5.258-4.347,8.584-2.38a11.3,11.3,0,0,1,3.358,3.328c1.075,1.648.3,3.706-.26,5.378-.988,2.962-3.154,4.6-6.118,5.4a12.568,12.568,0,0,1-8.083-.6c-3.1-1.344-4.225-4.082-4.785-7.24C6.7-26.728,17.277-31.475,25.44-28.829,26.9-28.356,27.524-30.651,26.071-31.122Z" fill="#f6b45e"/>
              <path id="Path_11795" data-name="Path 11795" d="M18.919-33.594H23.56l-2.321-4.04Z" fill="#f6b45e"/>
              <path id="Path_11796" data-name="Path 11796" d="M11.851-31.632l4.407-1.451-3.469-3.112Z" fill="#f6b45e"/>
              <path id="Path_11797" data-name="Path 11797" d="M6.566-27.485,9.909-30.7,5.437-32.006Z" fill="#f6b45e"/>
              <path id="Path_11798" data-name="Path 11798" d="M3.8-21.343l1.415-4.419-4.556.977Z" fill="#f6b45e"/>
              <path id="Path_11799" data-name="Path 11799" d="M4.279-14.67l-.528-4.61L0-16.516Z" fill="#f6b45e"/>
              <path id="Path_11800" data-name="Path 11800" d="M7.763-8.328,5.22-12.209,3.112-8.054Z" fill="#f6b45e"/>
              <path id="Path_11801" data-name="Path 11801" d="M13.2-4.269,9.033-6.314l.3,4.65Z" fill="#f6b45e"/>
              <path id="Path_11802" data-name="Path 11802" d="M20.953-4.151l-4.634.231L18.838,0Z" fill="#f6b45e"/>
              <path id="Path_11803" data-name="Path 11803" d="M28.011-7.043l-4.048,2.27,4,2.389Z" fill="#f6b45e"/>
              <path id="Path_11804" data-name="Path 11804" d="M32.444-12.386,29.862-8.531l4.648.322Z" fill="#f6b45e"/>
              <path id="Path_11805" data-name="Path 11805" d="M33.517-19.9l-.311,4.628,4.187-2.044Z" fill="#f6b45e"/>
              <path id="Path_11806" data-name="Path 11806" d="M29.34-26.551l2.677,3.791,1.963-4.225Z" fill="#f6b45e"/>
            </g>
          </svg>
      <p>
        We help tourists to know and have an experience with all the great destinations that they can travel to
in Botswana and outside, creating unforgettable lifetime memories in Africa.
      </p>
      <svg xmlns="http://www.w3.org/2000/svg" width="37.393" height="37.634" viewBox="0 0 37.393 37.634">
            <g id="Group_1420" data-name="Group 1420" transform="translate(0 37.634)">
              <path id="Path_11794" data-name="Path 11794" d="M26.071-31.122A17.057,17.057,0,0,0,10.7-28.848c-4.257,3.1-5.307,8.283-4.431,13.228C7.786-7.049,17.151-4.4,24.468-7.669c3.617-1.619,5.718-5.5,6.073-9.348.39-4.206-3.353-7.873-7.12-9.145-4.5-1.52-8.464.656-10.068,4.986s.867,7.9,5.507,8.155A4.654,4.654,0,0,0,23.608-16.5c.509-2.348-2.229-3.863-3.993-4.536-1.434-.547-2.049,1.752-.631,2.293a5.959,5.959,0,0,1,1.414.707q1.087,2.186-.7,2.535a4.18,4.18,0,0,1-3.1-.519c-1.957-1-1.579-3.09-.845-4.756,1.6-3.624,5.258-4.347,8.584-2.38a11.3,11.3,0,0,1,3.358,3.328c1.075,1.648.3,3.706-.26,5.378-.988,2.962-3.154,4.6-6.118,5.4a12.568,12.568,0,0,1-8.083-.6c-3.1-1.344-4.225-4.082-4.785-7.24C6.7-26.728,17.277-31.475,25.44-28.829,26.9-28.356,27.524-30.651,26.071-31.122Z" fill="#f6b45e"/>
              <path id="Path_11795" data-name="Path 11795" d="M18.919-33.594H23.56l-2.321-4.04Z" fill="#f6b45e"/>
              <path id="Path_11796" data-name="Path 11796" d="M11.851-31.632l4.407-1.451-3.469-3.112Z" fill="#f6b45e"/>
              <path id="Path_11797" data-name="Path 11797" d="M6.566-27.485,9.909-30.7,5.437-32.006Z" fill="#f6b45e"/>
              <path id="Path_11798" data-name="Path 11798" d="M3.8-21.343l1.415-4.419-4.556.977Z" fill="#f6b45e"/>
              <path id="Path_11799" data-name="Path 11799" d="M4.279-14.67l-.528-4.61L0-16.516Z" fill="#f6b45e"/>
              <path id="Path_11800" data-name="Path 11800" d="M7.763-8.328,5.22-12.209,3.112-8.054Z" fill="#f6b45e"/>
              <path id="Path_11801" data-name="Path 11801" d="M13.2-4.269,9.033-6.314l.3,4.65Z" fill="#f6b45e"/>
              <path id="Path_11802" data-name="Path 11802" d="M20.953-4.151l-4.634.231L18.838,0Z" fill="#f6b45e"/>
              <path id="Path_11803" data-name="Path 11803" d="M28.011-7.043l-4.048,2.27,4,2.389Z" fill="#f6b45e"/>
              <path id="Path_11804" data-name="Path 11804" d="M32.444-12.386,29.862-8.531l4.648.322Z" fill="#f6b45e"/>
              <path id="Path_11805" data-name="Path 11805" d="M33.517-19.9l-.311,4.628,4.187-2.044Z" fill="#f6b45e"/>
              <path id="Path_11806" data-name="Path 11806" d="M29.34-26.551l2.677,3.791,1.963-4.225Z" fill="#f6b45e"/>
            </g>
          </svg>
      <p>
        “Experience” and feel of the destination is what, we at Afrigetaway, aim at achieving when creating
travel plans for our customer. Our team has many years of experience and strategic partnerships to
ensure your excursion runs smoothly.
      </p>

      <svg xmlns="http://www.w3.org/2000/svg" width="37.393" height="37.634" viewBox="0 0 37.393 37.634">
            <g id="Group_1420" data-name="Group 1420" transform="translate(0 37.634)">
              <path id="Path_11794" data-name="Path 11794" d="M26.071-31.122A17.057,17.057,0,0,0,10.7-28.848c-4.257,3.1-5.307,8.283-4.431,13.228C7.786-7.049,17.151-4.4,24.468-7.669c3.617-1.619,5.718-5.5,6.073-9.348.39-4.206-3.353-7.873-7.12-9.145-4.5-1.52-8.464.656-10.068,4.986s.867,7.9,5.507,8.155A4.654,4.654,0,0,0,23.608-16.5c.509-2.348-2.229-3.863-3.993-4.536-1.434-.547-2.049,1.752-.631,2.293a5.959,5.959,0,0,1,1.414.707q1.087,2.186-.7,2.535a4.18,4.18,0,0,1-3.1-.519c-1.957-1-1.579-3.09-.845-4.756,1.6-3.624,5.258-4.347,8.584-2.38a11.3,11.3,0,0,1,3.358,3.328c1.075,1.648.3,3.706-.26,5.378-.988,2.962-3.154,4.6-6.118,5.4a12.568,12.568,0,0,1-8.083-.6c-3.1-1.344-4.225-4.082-4.785-7.24C6.7-26.728,17.277-31.475,25.44-28.829,26.9-28.356,27.524-30.651,26.071-31.122Z" fill="#f6b45e"/>
              <path id="Path_11795" data-name="Path 11795" d="M18.919-33.594H23.56l-2.321-4.04Z" fill="#f6b45e"/>
              <path id="Path_11796" data-name="Path 11796" d="M11.851-31.632l4.407-1.451-3.469-3.112Z" fill="#f6b45e"/>
              <path id="Path_11797" data-name="Path 11797" d="M6.566-27.485,9.909-30.7,5.437-32.006Z" fill="#f6b45e"/>
              <path id="Path_11798" data-name="Path 11798" d="M3.8-21.343l1.415-4.419-4.556.977Z" fill="#f6b45e"/>
              <path id="Path_11799" data-name="Path 11799" d="M4.279-14.67l-.528-4.61L0-16.516Z" fill="#f6b45e"/>
              <path id="Path_11800" data-name="Path 11800" d="M7.763-8.328,5.22-12.209,3.112-8.054Z" fill="#f6b45e"/>
              <path id="Path_11801" data-name="Path 11801" d="M13.2-4.269,9.033-6.314l.3,4.65Z" fill="#f6b45e"/>
              <path id="Path_11802" data-name="Path 11802" d="M20.953-4.151l-4.634.231L18.838,0Z" fill="#f6b45e"/>
              <path id="Path_11803" data-name="Path 11803" d="M28.011-7.043l-4.048,2.27,4,2.389Z" fill="#f6b45e"/>
              <path id="Path_11804" data-name="Path 11804" d="M32.444-12.386,29.862-8.531l4.648.322Z" fill="#f6b45e"/>
              <path id="Path_11805" data-name="Path 11805" d="M33.517-19.9l-.311,4.628,4.187-2.044Z" fill="#f6b45e"/>
              <path id="Path_11806" data-name="Path 11806" d="M29.34-26.551l2.677,3.791,1.963-4.225Z" fill="#f6b45e"/>
            </g>
          </svg>
      <p>
        When it comes to travel, we want our customers and to know that through us you are not only leaving
footprints in your destination but are contributing to the realization of the development of creative
tourism which shall economically benefit the local community of the destination. Travel with us and be
part of a community in support of a noble cause.
      </p>

      <svg xmlns="http://www.w3.org/2000/svg" width="37.393" height="37.634" viewBox="0 0 37.393 37.634">
            <g id="Group_1420" data-name="Group 1420" transform="translate(0 37.634)">
              <path id="Path_11794" data-name="Path 11794" d="M26.071-31.122A17.057,17.057,0,0,0,10.7-28.848c-4.257,3.1-5.307,8.283-4.431,13.228C7.786-7.049,17.151-4.4,24.468-7.669c3.617-1.619,5.718-5.5,6.073-9.348.39-4.206-3.353-7.873-7.12-9.145-4.5-1.52-8.464.656-10.068,4.986s.867,7.9,5.507,8.155A4.654,4.654,0,0,0,23.608-16.5c.509-2.348-2.229-3.863-3.993-4.536-1.434-.547-2.049,1.752-.631,2.293a5.959,5.959,0,0,1,1.414.707q1.087,2.186-.7,2.535a4.18,4.18,0,0,1-3.1-.519c-1.957-1-1.579-3.09-.845-4.756,1.6-3.624,5.258-4.347,8.584-2.38a11.3,11.3,0,0,1,3.358,3.328c1.075,1.648.3,3.706-.26,5.378-.988,2.962-3.154,4.6-6.118,5.4a12.568,12.568,0,0,1-8.083-.6c-3.1-1.344-4.225-4.082-4.785-7.24C6.7-26.728,17.277-31.475,25.44-28.829,26.9-28.356,27.524-30.651,26.071-31.122Z" fill="#f6b45e"/>
              <path id="Path_11795" data-name="Path 11795" d="M18.919-33.594H23.56l-2.321-4.04Z" fill="#f6b45e"/>
              <path id="Path_11796" data-name="Path 11796" d="M11.851-31.632l4.407-1.451-3.469-3.112Z" fill="#f6b45e"/>
              <path id="Path_11797" data-name="Path 11797" d="M6.566-27.485,9.909-30.7,5.437-32.006Z" fill="#f6b45e"/>
              <path id="Path_11798" data-name="Path 11798" d="M3.8-21.343l1.415-4.419-4.556.977Z" fill="#f6b45e"/>
              <path id="Path_11799" data-name="Path 11799" d="M4.279-14.67l-.528-4.61L0-16.516Z" fill="#f6b45e"/>
              <path id="Path_11800" data-name="Path 11800" d="M7.763-8.328,5.22-12.209,3.112-8.054Z" fill="#f6b45e"/>
              <path id="Path_11801" data-name="Path 11801" d="M13.2-4.269,9.033-6.314l.3,4.65Z" fill="#f6b45e"/>
              <path id="Path_11802" data-name="Path 11802" d="M20.953-4.151l-4.634.231L18.838,0Z" fill="#f6b45e"/>
              <path id="Path_11803" data-name="Path 11803" d="M28.011-7.043l-4.048,2.27,4,2.389Z" fill="#f6b45e"/>
              <path id="Path_11804" data-name="Path 11804" d="M32.444-12.386,29.862-8.531l4.648.322Z" fill="#f6b45e"/>
              <path id="Path_11805" data-name="Path 11805" d="M33.517-19.9l-.311,4.628,4.187-2.044Z" fill="#f6b45e"/>
              <path id="Path_11806" data-name="Path 11806" d="M29.34-26.551l2.677,3.791,1.963-4.225Z" fill="#f6b45e"/>
            </g>
          </svg>
      <p>
        Just send us an email detailing the destinations, activities and dates and we will get back to you with a
range of options to suit your budget. Sit back, relax and we’ll take care of all the details so you can
simply enjoy your holiday.
      </p>

      <div class="flex flex-col justify-center pb-8 mt-4">

        <p class="font-semibold ">We are located at:</p>
        <div class="whitespace-pre-line font-semibold">Molepolole,
          Plot 17989, 
          Mogogoru Ward,
          Next to Mafenyatla Mall, Molepolole, Botswana
        </div>
      <a class="font-semibold" href="https://www.facebook.com/afrigetaway">
        <svg fill="#203ecf" width="40" height="40" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><path d="M59.5 1H4.5C2.5 1 1 2.6 1 4.5V59.5C1 61.5 2.6 63 4.5 63H34.1V38.9H26.1V29.6H34.1V22.7C34.1 14.7 38.9 10.3 46.1 10.3C48.5 10.3 50.9 10.4 53.3 10.7V19H48.5C44.7 19 43.9 20.8 43.9 23.5V29.4H53L51.7 38.8H43.7V62.6H59.5C61.5 62.6 63 61.1 63 59.1V4.5C62.9 2.5 61.3 1 59.5 1Z"/></svg>Click here to chat with us.
      </a>
    </div>
      <div class="flex flex-row justify-center mb-2 pb-8 md:pb-10 mt-2">
        <img class="w-52 h-full" src="/images/logo.png" alt="Afrigetaway">
      </div>
      <div class="flex flex-row justify-center mb-2 pb-8 md:pb-12">
        <img class="w-40 md:w-52 h-full" src="/images/hatab.png" alt="Hatab">
        <img class="w-40 md:w-52 h-full" src="/images/tourism.png" alt="Tourism">
      </div>
    </div>
    
    </div>
    </div>
   
  </div>
</template>
<script>
export default {
  name: 'AboutUs',
  components: {
  },
  data: function () {
    return {
      baseUrl: this.$store.state.baseUrl,
      loggedIn: this.$store.state.loggedIn
    }
  },
  methods: {
  },
  mounted () {
  },
  created () {
  }
}
</script>
<style scoped>
  .section h2 {
    color: black;
    font-size: 2em;
  
  }
  svg{
    display: inline-flex;
  }
</style>
