<template>
    <div class="flex flex-row items-center">
        <input class="ring-afyellow-normal text-afyellow-normal checked:bg-afyellow-normal border-afyellow-normal focus:ring-afyellow-normal mx-2 h-5 w-5 " v-bind="$attrs"
        type="radio" v-model="model" :value="value" :disabled="disabled"/>
    <p :class="disabled ? 'line-through':''">{{ label }}</p>
    </div>
    
</template>
<script>
import { computed, defineEmits } from "vue";
export default({
    name:"RadioButton",
    props:[
        'modelValue',
        'value',
        'label',
        'disabled'
    ],
    emits:['update:modelValue'],
    data:function(){
        return{

        }
    },
    computed:{
       
        model: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            },
        }

    }
})





</script>