<template>
  <div class="bg-[url(images/afribg.png)] bg-center">
    
    <div class="h-4/5 overflow-y-auto overflow-x-hidden pb-10" >
    <culturaltravel :active="isCultural"></culturaltravel>
    <luxurysafari :active="isLuxury"></luxurysafari>
    <creativetravel :active="isCreative"></creativetravel>
    <blog :active="isBlog"></blog>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import culturaltravel from '@/components/public/CulturalTravel.vue'
import luxurysafari from '@/components/public/LuxurySafari.vue'
import creativetravel from '@/components/public/CreativeTravel.vue'
import blog from '@/components/public/blog.vue'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'HomePage',
  components: {
    culturaltravel,
    luxurysafari,
    creativetravel,
    blog
  },
  data: function () {
    return {
      baseUrl: this.$store.state.baseUrl,
      packages: [
        {
          id: 1,
          header_img: '/images/culture-dance.jpg',
          title: 'Chobe,Botswana',
          price: 1500.00
        },
        {
          id: 2,
          header_img: '/images/tented-camp.jpg',
          title: 'Okavango,Botswana',
          price: 4500.00
        },
        {
          id: 3,
          header_img: '/images/tented-camp.jpg',
          title: 'Okavango,Botswana',
          price: 4500.00
        }
      ],
      showpackages: true,
      showdestinations: false,
      showblog: true,
      showcontactus: true,
      showCultural: true,
      loggedIn: this.$store.state.loggedIn
    }
  },
  methods: {
    getpagedetails: function () {
      axios(
        {
          method: 'post',
          url: this.$store.state.baseUrl + '/api/public/home'
        })
        .then((response) => {
          console.log(response)
          this.packages = response.data.packages
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  computed: {
    ...mapState(['homePageState']),
    ...mapGetters(['isLuxury', 'isCultural', 'isCreative', 'isBlog', 'isDestinations'])
  },
  watch: (() => this.$store.getters.getHomePageState, (value) => {
    console.log('Watcher: ' + value)
  }),
  mounted () {
    this.getpagedetails()
  },
  created () {
  }
}
</script>
<style scoped>
  .section h2 {
    color: black;
    font-size: 2em;
  }
</style>
