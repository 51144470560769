<template>
  <div class="flex flex-col w-full h-full">
    <LogoutComponent v-if="isAuthenticated"></LogoutComponent>
    <publicnav></publicnav>
    <div class="flex flex-col ">
      <router-view/>
    </div>
  
    <Bottomnav v-if="showBottomNav"/>

  </div>
</template>
<script>
import LogoutComponent from './components/LogoutComponent.vue';
import publicnav from './components/publicnav.vue'
import { mapGetters } from 'vuex';
import Bottomnav from './components/bottomnav.vue';
import router from '@/router'
export default {
  name: 'App',
  components: {
    publicnav,
    LogoutComponent,
    Bottomnav
},
  data: function () {
    return {
      loggedIn: this.$store.state.loggedIn
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated','loggedInUser']),

    showBottomNav() {

      const _routes = ['/'];
      return _routes.includes(router.currentRoute.value.path);

    }
  }
 
  
}
</script>
<style>
</style>
