<script setup>
import { ref, inject } from 'vue';


const props = defineProps({
  title: {
    type: String,
    default: 'Tab'
  }
});

const isActive = ref(true);

const selectedTitle = inject('selectedTitle')
</script>

<template>
  <div class="py-4 px-1 rounded-lg" v-show='title == selectedTitle'>
    <slot></slot>
  </div>
</template>

<style lang="css">

</style>
