<template>
  <div class="pagecontainer bg-white">
    <adminheader></adminheader>
    <div class="adminbody">
      <sidenav active="Blogs"></sidenav>
      <div class="pagecontent">
        <div class="page-title">Blogs</div>
        <div class="bg-slate-100 rounded p-4">
          <span class="bg-emerald-400 p-2 text-white rounded shadow-sm hover:scale-110 duration-500 font-bold cursor-pointer" @click.prevent="openNewBlog">New</span>
        </div>
        <div class="w-full overflow-auto">
          <blogslist :blogitem=item v-for="item in blogs" :key="item.ref"></blogslist>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script>
import adminheader from '@/components/admin/header.vue'
import axios from 'axios'
import Sidenav from '@/components/sidenav.vue'
import blogslist from '@/views/Admin/Blogs/list.vue'
import router from '@/router'

export default {
  name: 'BlogDashboard',
  components: {
    adminheader,
    Sidenav,
    blogslist
  },
  data: function () {
    return {
      blogs: []
    }
  },
  methods: {
    getblogs: function () {
      axios(
        {
          method: 'post',
          url: this.$store.state.baseUrl + '/api/admin/blogs',
          headers: { Authorization: 'Bearer ' + this.$store.state.token }
        })
        .then((response) => {
          console.log(response)
          this.blogs = response.data.blogs
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    openNewBlog: function () {
      router.push('/admin/blog/new')
    }
  },
  created () {
    this.getblogs()
  }
}
</script>
<style scoped>
.pagecontainer {
    display: flex;
    flex-direction: column;
  }

  .btn-open-package {
    background-color: #4FC3F7;
    color: #fff;
    font-weight: 700;
    padding: 10px 10px;
    border-radius: 5px;
  }
  .adminbody {
    display: grid;
    grid-template-columns: 250px 1fr;
  }

  .pagecontent {
    padding: 10px;
    display: flex;
    max-height: 80vh;
    overflow: auto;
    flex-direction: column;
  }

  .page-title {
    padding: 20px;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .page-list {
    width: 800px;
  }

  .toolbox {
    width: 100%;
    background: #9E9E9E;
    height: 10vh;
    padding: 2vh;
    display: grid;
    grid-template-columns: 200px 1fr;
  }

  .newbutton {
    background: #00ACC1;
    color: #fff;
    font-weight: 600;
    width: 150px;
    padding: 10px;
    border-radius: 5px;
  }
</style>
