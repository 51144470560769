import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

const store = createStore({
  state: {
    liveUrl: 'https://afrigetaway.com/app',
    localUrl: 'http://localhost:8000',
    baseUrl: 'https://afrigetaway.com/app',
    token: localStorage.getItem('access_token') || null,
    loggedin: localStorage.getItem('loggedin') || false,
    app_busy: false,
    loggedinUser: {},
    bookings: [],
    selectedblog: {},
    selectedPackage: {},
    selectedTravelPackage:{},
    selectedBooking:{},
    homePageState: 'cultural'
  },
  mutations: {
    setToken (state, token) {
      console.log("set token....");
      state.token = token
      localStorage.setItem('access_token', token)
      localStorage.setItem('loggedin', true)
      state.loggedin = true
    },
    setBusy (state) {
      state.app_busy = true
    },
    setIdle (state) {
      state.app_busy = false
    },
    setLoggedInUser (state, user) {
      state.loggedinUser = user
      localStorage.setItem('loggedInUser', user)
    },
    setSelectedBlog (state, blog) {
      console.log("change selected blog state");
      state.selectedblog = blog
      //localStorage.setItem('selectedBlog', blog)
    },
    setSelectedPackage (state, afripackage) {
      state.selectedPackage = afripackage
      localStorage.setItem('selectedPackage', afripackage)
    },
    setSelectedTravelPackage (state, selectedPackage) {
      state.selectedTravelPackage = selectedPackage
    },
    setSelectedBooking (state, booking) {
      state.selectedBooking = booking
    },
    setHomePageState (state, homecat) {
      console.log('set home page state: ' + homecat)
      state.homePageState = homecat
      //localStorage.setItem('homePageState', homecat)
    },
    setLogout (state) {
      state.token = null
      localStorage.removeItem('access_token')
      localStorage.removeItem('loggedin', false)
      state.loggedin = false
      state.homePageState = 'cultural'
      localStorage.removeItem('loggedInUser', {})

    },
  },
  getters: {
    getHomePageState (state) {
      return state.homePageState
    },
    isLuxury (state) {
      console.log(state.homePageState === 'luxury')
      return state.homePageState === 'luxury'
    },
    isCultural (state) {
      console.log(state.homePageState === 'cultural')
      return state.homePageState === 'cultural'
    },
    isCreative (state) {
      console.log(state.homePageState === 'creative')
      return state.homePageState === 'creative'
    },
    isBlog (state) {
      return state.homePageState === 'blog'
    },
    isDestinations (state) {
      return state.homePageState === 'destinations'
    },
    isAuthenticated(state) {
      return state.loggedin
    },
    loggedInUser(state){
      return state.loggedinUser
    }
  },
  actions:{
    setSelectedTravelPackage ({ commit } , _package) {
      commit('setSelectedTravelPackage', _package)
    },
    setTokenAction ({ commit } , token) {
      console.log("set action token action")
      commit('setToken', token)
    },
    setLogoutAction ({ commit }) {
      commit('setLogout')
    }
  },
  plugins: [createPersistedState()],
})

export default store
