<template>
  <div class="admin-nav">
    <div class="title">Admin</div>
    <div class="user-container"><span class="user-content">{{user.name}}</span></div>
  </div>
</template>
<script>
export default {
  name: 'AdminNav',
  data: function () {
    return {
      user: this.$store.state.loggedinUser
    }
  },
  mounted () {
    console.log('Logged in User :' + this.user.name)
  }
}
</script>

<style scoped>
.admin-nav {
  box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.29);
  -webkit-box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.29);
  -moz-box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.29);
  background: #424242;
  width: 100vw;
  height: 8vh;
  padding: 5px 5px;
  display: grid;
  grid-template-columns: 300px 1f;
  align-items: center;
}

.title {
 color: #fff;
 font-weight: 600;
 font-size: 18px;
}

.user-container {
  display: flex;
  flex-direction: row-reverse;
}

.user-content {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}

</style>
