<template>
  <div class="pagecontainer bg-white">
    <div class="adminbody">
      <sidenav active="Destinations"></sidenav>
      <div class="pagecontent">
        <div class="page-title">Destinations</div>
        <div class="flex">
          <span class="newbutton" @click="toggleNewForm()">New</span>
        </div>
        <div v-if="newform" class="flex flex-col mt-2 bg-gray-100 p-4 rounded w-full">
          <span class="text-xl">Create Destination form</span>
          <div class="flex gap-2 align-center mt-2">
            <span>Name:</span>
            <span><input v-model="destination.name" type="text" class="border rounded"></span>
          </div>
          <div class="flex flex-col gap-2 align-center mt-4">
            <span class="text-bold">Description:</span>
            <textarea v-model="destination.description" type="text" class="border rounded w-1/2 h-20"></textarea>
          </div>
          <div class="flex gap-2 align-center mt-2">
            <span>longitude:</span>
            <span><input v-model="destination.long" type="text" class="border rounded"></span>
            <span>lattitude:</span>
            <span><input v-model="destination.lat" type="text" class="border rounded"></span>
          </div>
          <div class="flex gap-2">
            <span class="p-2 rounded shadow bg-teal-400 w-1/5 mt-4 text-white" @click="saveDestination()">save</span>
            <span class="p-2 rounded shadow bg-yellow-600 w-1/5 mt-4 text-white" @click="toggleNewForm()">close</span>
          </div>
        </div>
        <div class="page-list">
          <div class="flex flex-col">
            <span v-for="item in destinations" :key="item.name">
              {{ item.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Sidenav from '@/components/sidenav.vue'
import router from '@/router'

export default {
  name: 'BlogDashboard',
  components: {
    Sidenav
  },
  data: function () {
    return {
      destinations: [],
      newform: false,
      destination: {
        name: "",
        description: "",
        long: "",
        lat: ""
      }
    }
  },
  methods: {
    getDestinations: function () {
      axios(
        {
          method: 'post',
          url: this.$store.state.baseUrl + '/api/get/destinations',
          headers: { Authorization: 'Bearer ' + this.$store.state.token }
        })
        .then((response) => {
          console.log(response)
          this.destinations = response.data.destinations
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    toggleNewForm: function () {
      this.newform = !this.newform
    },
    saveDestination: function() {
      this.toggleNewForm()
      const myFormData = new FormData()
      myFormData.set('name', this.destination.name)
      myFormData.set('description', this.destination.description)
      myFormData.set('long', this.destination.long)
      myFormData.set('lat', this.destination.lat)
      axios(
        {
          method: 'post',
          data: myFormData,
          url: this.$store.state.baseUrl + '/api/create/destination',
          headers: { Authorization: 'Bearer ' + this.$store.state.token }
        })
        .then((response) => {
          console.log(response)
          this.destinations = response.data.destinations
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  created () {
    this.getDestinations()
  }
}
</script>
<style scoped>
.pagecontainer {
    display: flex;
    flex-direction: column;
  }

  .btn-open-package {
    background-color: #4FC3F7;
    color: #fff;
    font-weight: 700;
    padding: 10px 10px;
    border-radius: 5px;
  }
  .adminbody {
    display: grid;
    grid-template-columns: 250px 1fr;
  }

  .pagecontent {
    padding: 10px;
    display: flex;
    min-height: 50vh;
    flex-direction: column;
  }

  .page-title {
    padding: 20px;
    font-size: 1.5rem;
    font-weight: 600;
  }

  .page-list {
    width: 800px;
  }

  .toolbox {
    width: 100%;
    background: #9E9E9E;
    height: 10vh;
    padding: 2vh;
    display: grid;
    grid-template-columns: 200px 1fr;
  }

  .newbutton {
    background: #00ACC1;
    color: #fff;
    font-weight: 600;
    width: 150px;
    padding: 10px;
    border-radius: 5px;
  }
</style>
