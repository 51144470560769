<template>
  <div class="flex flex-col" v-if="active" >
    <div class="flex flex-col h-screen w-screen justify-center items-center">
      <div class="bg-white p-4 rounded h-3/4 w-3/4 mb-20">
        <div class="overflow-y-auto h-full">
        <div class="md:h-full w-full flex flex-col md:flex-row overflow-hidden overscroll-y-auto">
          <div class="flex flex-col md:w-1/2 justify-center items-center">
            <p class="text-2xl font-extrabold px-5 text-center">Creative Tourism</p>
            <div class="mt-4 px-2.5 md:px-15 whitespace-pre-wrap">
              “Have the feel of the destination”
              

              
              Co-design and co-create towards an engaged and authentic experience, participative learning in the arts,heritage, or special character of a place.
            </div>
            <img class="hidden md:block object-contain mb-5" src="/images/kids.png" alt="">
          </div>
          <div class="flex flex-col md:w-1/2">
            <img v-lazy="{src:'/images/package_bg_creative.jpg',loading:'/images/loader.gif',error:'/images/loader.gif'}" alt="" class="w-full h-full object-cover rounded-t bg-slate-200"/>
            <button class="p-2 bg-teal-500 flex justify-center items-center text-white font-bold rounded-b cursor-pointer focus:animate-pulse transition duration-150 ease-in-out" @click.prevent="openPackages('creative')">View Travel Packages</button>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from '@/router'
import bgImg from '@/assets/images/afribg.png'
export default {
  name: 'CreativeSafari',
  props: ['active'],
  data: function () {
    return {
      jumtitles: ['EXPLORE', 'TRAVEL', 'CONNECT'],
      currtitle: 0,
      packages: [],
      bgImg,
      search: 0
    }
  },
  methods: {
    openPackages: function (_package) {
      this.$store.commit('setSelectedPackage', _package)
      router.push('/list/packages')
    }
  },
  mounted: function () {
    setInterval(() => {
      if (this.currtitle < this.jumtitles.length - 1) {
        this.currtitle++
      } else {
        this.currtitle = 0
      }
    }, 1000)
  }

}
</script>
<style>

#cloud_bg {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.jumbo-overlay-header {
  font-size: 3.5rem;
  font-weight: 800;
  color: #fff !important;
}

.jumbo-overlay-content-text {
  color: #fff !important;
}

.intro-header {
  width:100%;
}

.intro-header img{
  height: 50vh;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

.jumbo-accent-pic {
  display: none;
  position: absolute;
  height: 60vh;
  width: auto;
  background-color: #86BF9F;
  left: 5%;
  top: 19vh;
  transform: translate(-50%, -50%);
  z-index: -10;
  box-shadow: 2px 4px 5px -3px rgba(0,0,0,0.75);
  -webkit-box-shadow: 2px 4px 5px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 2px 4px 5px -3px rgba(0,0,0,0.75);
}

.searchbox {
  background:#86BF9F;
  height: 50px;
  width: 60%;
  box-shadow: 2px 4px 5px -3px rgba(0,0,0,0.75);
  -webkit-box-shadow: 2px 4px 5px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 2px 4px 5px -3px rgba(0,0,0,0.75);
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  border: none;
}

  .searchbox:focus {
    border: none;
  }

  .home-carousel {
    width: 100%;
  }

  .home-carousel img {
    width: 100%;
    object-fit: cover;
  }

  .home-calltoaction {
    width: 50%;
    padding: 5%;
  }

  .book-btn {
    background-color: #FBC02D;
    padding: 10px 30px;
    border-radius: 15px;
    color: #fff !important;
    transform: translate(50%, 0%);
    box-shadow: 2px 4px 5px -3px rgba(0,0,0,0.75);
    -webkit-box-shadow: 2px 4px 5px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 4px 5px -3px rgba(0,0,0,0.75);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    gap: 10px;
  }

#theme_accent_body {
  background-repeat: repeat-x;
  width: 100%;
  height: 1.5vh;
  z-index: 100;
  position: absolute;
  left:0px;
  top: 61.5vh;
}

.a{fill:none;clip-rule:evenodd;}
.b{clip-path:url(#a);}
.c{fill:url(#b);}
.d{clip-path:url(#c);}
.e{fill:url(#d);}
.f{clip-path:url(#e);}
.g{fill:url(#f);}
.h{clip-path:url(#g);}
.i{fill:url(#h);}
</style>
