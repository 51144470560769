<template>
<div class="flex flex-col m-2 relative items-center h-screen pt-8">
  <div class="overflow-y-auto h-4/5 w-full">
    
    <div class="flex flex-row w-full justify-end px-4 mx-2 my-2">

      <button class="border-gray-500 border-2 rounded-lg p-2 " @click.prevent="toggleFilters()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-gray-500">
  <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
</svg>

      </button>
    </div>
    <div v-if="showfilters" class="flex flex-col md:flex-row px-4 py-8 w-full rounded-lg border-gray-300 border ">
    <div class="flex flex-col w-full px-4 py-4">
        <label for="minmax-range" class="block mb-12 text-sm font-medium text-gray-900">Price Range</label>
      <Slider class="slider-red" :lazy="true" v-model="price" :min=0 :max=50000 :tooltips="true" />
    </div>
    <div class="flex flex-col w-full px-4 py-4">
         <label for="minmax-range" class="block mb-12 text-sm font-medium text-gray-900">Number of Days</label>
         <Slider class="slider-red" :lazy="true" v-model="days" :min=1 :max=50 :tooltips="true" />
      
        {{ days }}
      </div>
      <div class="flex flex-row justify-between items-center px-4 py-4">
       
        <button class="bg-aforange-normal px-4 py-2 rounded-lg text-white mr-1" @click.prevent="filterPackages()">Filter</button>

        <button class="bg-afgreen-normal px-4 py-2 rounded-lg text-white " @click.prevent="resetPackages()">Reset</button>
      </div>

     
    </div>
    <div class="flex flex-row w-full md:justify-end px-4 mx-2" v-if="showfilters  && filtered">
      <p class="text-xs">Search results for prices between {{ price[0]}} - {{ price[1] }} and {{ days }} days or less</p>
    </div>
    <div class="justify-center items-center p-4">
      <div class="flex flex-wrap justify-center gap-8 px-2 py-2 pb-8 mb-8" v-if="packages && !filtered">
        <div class="transition ease-in-out delay-150 bg-black hover:-translate-y-1 hover:scale-110 hover:bg-grey-500 duration-300 p-2 rounded-xl w-60 md:w-96 cursor-pointer" v-for="travelpackage in packages" :key="travelpackage.ref">
          <div class="flex flex-col" v-on:click.prevent="openPackage(travelpackage)">
            <div class="flex flex-col relative">
              <span v-if="travelpackage.days" class="bg-afgreen-normal text-white text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2  border border-gray-500 absolute right-0.5 top-1">
  <svg aria-hidden="true" class="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
 {{ travelpackage.days }} days
</span>
              <img v-lazy="{ src: mediaUrl + travelpackage.headerimage.ref, loading: '/images/loader.gif', error: '/images/loader.gif' }" class="h-72 object-cover bg-slate-200 rounded-lg" alt="" v-if="travelpackage.headerimage"/>
            </div>
           
            <div class="flex flex-row justify-between">
              <div class=" text-yellow-500 text-lg font-bold truncate">
                  {{ travelpackage.name }}
              </div>
              <div class="text-sm text-teal-500 px-1 justify-end overflow-hidden whitespace-pre-wrap" v-if="travelpackage.availability != 'local'">From USD {{ travelpackage.pricing.intl_price }}
              </div>
              <div v-else class="text-sm text-teal-500 px-1 justify-end overflow-hidden whitespace-pre-wrap">From BWP {{ travelpackage.pricing.local_price }}
              </div>
            </div>
            <div class="flex- flex-row h-14 p-2 text-white text-sm truncate">
            {{ travelpackage.description }}
          </div>
          <div class="flex flex-row justify-center md:justify-end mt-1">
            <button @click="openPackage(travelpackage)" class="bg-aforange-normal p-2 text-xs rounded-lg shadow-md ">View

            </button>

          </div>
          </div>
         
        </div>
      </div>
      <div class="flex flex-wrap justify-center gap-8 px-2 py-2 pb-8 mb-8" v-if="filtered_packages && filtered">
        <div class="transition ease-in-out delay-150 bg-black hover:-translate-y-1 hover:scale-110 hover:bg-grey-500 duration-300 p-2 rounded-xl w-60 md:w-96 cursor-pointer" v-for="travelpackage in filtered_packages" :key="travelpackage.ref">
          <div class="flex flex-col" v-on:click.prevent="openPackage(travelpackage)">
            <div class="flex flex-col relative">
              <span v-if="travelpackage.days" class="bg-afgreen-normal text-white text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 border border-gray-500 absolute right-0.5 top-1">
  <svg aria-hidden="true" class="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
  {{ travelpackage.days }} days
</span>
              <img v-lazy="{ src: mediaUrl + travelpackage.headerimage.ref, loading: '/images/loader.gif', error: '/images/loader.gif' }" class="h-72 object-cover bg-slate-200 rounded-lg" alt="" v-if="travelpackage.headerimage"/>
            </div>
           
            <div class="flex flex-row justify-between">
              <div class=" text-yellow-500 text-lg font-bold truncate">
                  {{ travelpackage.name }}
              </div>
              <div class="text-sm text-teal-500 px-1 justify-end overflow-hidden whitespace-pre-wrap" v-if="travelpackage.availability != 'local'">From USD {{ travelpackage.pricing.intl_price }}
              </div>
              <div v-else class="text-sm text-teal-500 px-1 justify-end overflow-hidden whitespace-pre-wrap">From BWP {{ travelpackage.pricing.local_price }}
              </div>
            </div>
            <div class="flex flex-row h-14 p-2 text-white text-sm truncate">
            {{ travelpackage.description }}
          </div>

          <div class="flex flex-row justify-center md:justify-end">
            <button @click="openPackage(travelpackage)" class="bg-aforange-normal p-2 text-xs rounded-lg shadow-md flex flex-row">View
              
            </button>

          </div>
          </div>
         
         
        </div>
      </div>
    </div>
    <div v-if="loading"><p>Please wait while loading....</p></div>
    <div v-if="hasError"><p>{{ error }}</p></div>
    <div v-if="!loading && !hasError && packages.length <= 0 ">
      <p>No packages found</p>
    </div>
  </div>
</div>
</template>
<script>
import router from '@/router'
import axios from 'axios'
// import packagefilter from '../packagefilter.vue'
import { mapState } from 'vuex'
import Slider from '@vueform/slider'

export default {
    name:"List-Packages",
    components:{
    Slider
},
  data: function () {
    return {
      baseUrl: this.$store.state.baseUrl,
      mediaUrl:this.$store.state.baseUrl+'/media/get/',
      token: this.$store.state.token,
      packages: [],
      loading:false,
      error:'',
      price:[500,5000],
      days:3,
      sliderMax:500,
      sliderMin:0,
      showfilters:false,
      filtered:false,
      filtered_packages :[]
    }
  },
  props: {
    travelpackage: {
      headerimage: null
    },
  },
  methods: {
    resetPackages:function(){

      this.getpackages();
    },
    openPackage: function (item) {
        this.$store.dispatch('setSelectedTravelPackage',item).then(()=>{
            router.push({ path: `/booking/${item.ref}` })
        });
    },
    toggleFilters:function()
    {
      this.showfilters = !this.showfilters
    },
    getItenaryDays: function () {
      const myFormData = new FormData()
      myFormData.set('package', this.travelpackage.ref)

      axios(
        {
          method: 'post',
          data: myFormData,
          url: this.$store.state.baseUrl + '/api/admin/package/get/itenarydays',
          headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + this.$store.state.token, 'Access-Control-Allow-Origin': '*' }
        })
        .then((response) => {
          console.log(response)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getpackages: function () {
        this.loading = true;
      axios(
        {
          method: 'post',
          url: this.$store.state.baseUrl + '/api/get/packages'
        })
        .then((response) => {
            this.loading = false
                    console.log("Response: "+response.data.data)
                    if (typeof response.data.data != 'undefined' || response.data.data != null) {

                        const _data = response.data.data;
                        this.packages = _data.filter((_item) => {
                            console.log("In loop:"+JSON.stringify(_item));
                            return _item.category == this.selectedPackage
                        })
                        console.log("Packages: "+JSON.stringify(this.packages));
                        // this.packages = response.data.data
                    }
                })
        .catch(function (error) {
            this.loading = false
            this.error = error;
          console.log(error)
        })
    },
    filterPackages: function () {
      this.filtered = true;
      const _arrPackages = this.packages.filter((_package) => {

        if (_package.availability == "local") {
          return _package.pricing.local_price >= this.price[0] && _package.pricing.local_price <= this.price[1] && _package.days  <= this.days
        }
        else {
          return _package.pricing.intl_price >= this.price[0] && _package.pricing.intl_price <= this.price[1] && _package.days  <= this.days
        }
      })

      if(_arrPackages)
      {
         this.filtered_packages = _arrPackages
      }
    },
    resetPackages:function(){
      this.filtered = false;
      this.filtered_packages = []
      this.showfilters = false
      this.getpackages();
    }
  },
  computed:{
    ...mapState(['selectedPackage']),

    hasError : function(){
        return typeof this.error != 'undefined' && this.error != null && this.error != ''
    }
    

  },
  mounted () {
    if(this.selectedPackage)
    {
        this.getpackages();
    }
    //this.getItenaryDays()
  }
}
</script>
<style src="@vueform/slider/themes/default.css">
.slider-red {
  --slider-connect-bg: #EF4444;
  --slider-tooltip-bg: #EF4444;
  --slider-handle-ring-color: #EF444430;
}

</style>
<style scoped>

.pkg-title {
    font-size: 18px;
    font-weight:700;
    color: #FFD600;
  }

.pkg-price {
    font-size: 0.8rem;
    font-weight:600;
    color: #4DB6AC;
    border-radius: 5px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .package-container {
    padding: 5px;
    border-radius: 10px;
    background: #212121;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    -moz-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  .package-container:hover {
    transform: scale(1.01);
    transition-duration: 1s;
  }
  /* .package-container img {
    height: 200px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }  */

  .name-price {
    display: grid;
    grid-template-columns: 1fr 100px;
  }

  .pkg-desc {
    height: 3em;
    min-height: 70px;
    font-size: 0.8rem;
    color: #fff;
    text-overflow:ellipsis;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
  }
</style>
