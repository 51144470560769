<template>
    <div v-if="itenarydays.length > 0 && type == 'carousel'" class="md:h-96 overflow-y-auto">
      <div class="underline font-bold text-xl">Itinerary</div>
        <span v-if="hasSelectedDay && hasSelectedDayItems">Day {{ selectedDay.title }} of {{ itenarydays.length-1 }}</span>
        <div v-if="hasSelectedDayItems" class="flex flex-col border-b border-gray-200 pb-4 mt-7">
            <div v-for="item in selectedDay.items" :key="item.ref">
                <div class="flex items-center">
                    <i class="m-2 text-xl text-white bg-teal-500 rounded-sm shadow-sm p-2 font-bold lni"
                        :class="[item.type]"></i><span class="text-xl">{{ item.title }}</span>
                </div>
                <span class="text-xs">{{ item.description }}</span>
            </div>
        </div>
        <div class="flex flex-row justify-between mt-4" v-if="hasSelectedDay && hasSelectedDayItems">
            <span v-if="selectedIndex > 0" @click="fn_prevDay()"
                class="bg-yellow-600 text-white font-bold p-2 text-center justify-center items-center rounded-sm shadow-sm cursor-pointer">Previous
                Day</span>
            <span @click="fn_nextDay()"
                class="bg-teal-600 text-white font-bold p-2 text-center justify-center items-center  rounded-sm shadow-sm cursor-pointer">Next
                Day</span>
        </div>


    </div>
    <div v-if="itenarydays.length > 0 && type == 'list' " class="md:h-96 overflow-y-auto" >
      <span class="underline font-bold text-xl">Itinerary</span>
      <div v-for='(iternary,index) in itenarydays'>
        <span v-if="iternary && iternary.items && iternary.items.length > 0" class="text-lg font-bold">Day {{ iternary.title }} of {{ itenarydays.length-1 }}</span>
        <div v-if="iternary.items && iternary.items.length > 0" class="flex flex-col border-b border-gray-200 pb-4 mt-4">
         
            <div v-for="item in iternary.items" :key="item.ref">
              
                <div class="flex items-center">
                    <i class="m-2 text-lg text-white bg-teal-500 rounded-sm shadow-sm p-2 lni"
                        :class="[item.type]"></i><span class="text-lg">{{ item.title }}</span>
                </div>
                <span class="text-xs">{{ item.description }}</span>
            </div>
        </div>
      </div>
     
        

    </div>
</template>
  <script>
  import { mapState } from 'vuex'
  import axios from 'axios'
  export default {
    name: 'Iternary',
    components: {
  },
  props:{
    type:{
      type:String,
      default:"caurosel"
    }
  },
    data: function () {
      return {
        baseUrl: this.$store.state.baseUrl,
        token: this.$store.state.token,
        tpackage: {
          headerimage: {
            ref: ''
          }
        },
        showpackage: false,
        showbookingForm: true,
        itenarydays: [],
        selectedDay: {
          title: '0',
          items: []
        },
        selectedIndex: 0,
        packagegallery: [],
        showModal:false,
      }
    },
    methods: {

      getItenaryDays: function () {
        const myFormData = new FormData()
        myFormData.set('package', this.selectedTravelPackage.ref)
  
        axios(
          {
            method: 'post',
            data: myFormData,
            url: this.baseUrl + '/api/admin/package/get/itenarydays',
            headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + this.$store.state.token, 'Access-Control-Allow-Origin': '*' }
          })
          .then((response) => {
            console.log(response.data.itenarydays)
            this.itenarydays = response.data.itenarydays
            this.selectedDay = this.itenarydays[this.selectedIndex]
          })
          .catch(function (error) {
            console.log(error)
          })
      },
      fn_nextDay: function () {
        if (this.selectedIndex < this.itenarydays.length) {
          this.selectedIndex = this.selectedIndex + 1
          this.selectedDay = this.itenarydays[this.selectedIndex]
        }
        console.log('click: ' + this.selectedIndex)
      },
      fn_prevDay: function () {
        if (this.selectedIndex > 0) {
          this.selectedIndex = this.selectedIndex - 1
          this.selectedDay = this.itenarydays[this.selectedIndex]
        }
        console.log('click: ' + this.selectedIndex)
      },
     
    },
    computed:{
    ...mapState(['selectedTravelPackage']),

    hasSelectedTravelPackage(){
      return typeof this.selectedTravelPackage != 'undefined' && this.selectedTravelPackage != null
    },
    hasSelectedDay:function(){
      return this.selectedDay != undefined && this.selectedDay != null 
    },
    hasSelectedDayItems:function(){
      return (this.hasSelectedDay && this.selectedDay.items != undefined && this.selectedDay.items != null && this.selectedDay.items.length > 0)
    }
    
  },
    created () {
      if (this.hasSelectedTravelPackage) {
        this.getItenaryDays()
      }

     
    }
  }
  </script>
  <style scoped>
    #booking {
      display:flex;
      justify-content: flex-start;
      width:100%;
      min-height: 80vh;
    }
    .package-view-header-img {
      height: 400px;
      width: 100%;
      object-fit: cover;
    }
  
    .package-view {
      display: flex;
      width: 100%;
      flex-direction: column;
    }
  
    .package-details {
      margin-top: 2vh;
      margin: 3%;
      width: 94%;
    }
  
    .itenary {
      margin-top: 15px;
      display: flex;
      justify-content: flex-start;
    }
  
    .itenary .details {
      width:50%;
    }
  
    .itenary .gallery {
      width:50%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      overflow: scroll;
      height: 50vh;
    }
  
    .itenary .booking-form {
      width: 100%;
    }
  
    .itenary .gallery img{
      max-height: 150px;
      max-width: 200px;
    }
  
    .p-header-details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content:start;
      align-items: center;
    }
  
    .booking-btn {
      width: 40%;
      color: white;
      background-color: #00ACC1;
      border-radius: 5px;
      padding: 5px;
    }
  
    .moreinfo-btn {
      width: 40%;
      color: #00ACC1;
      border: 2px solid #00ACC1;
      border-radius: 5px;
      padding: 5px;
      margin-left: 5px;
    }
  
    .btn-container {
      display: flex;
      width:100%;
      /* width: 600px; */
    }
  
    .pricing {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  
    .title-disc {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: left;
      text-align: left;
    }
  </style>
  